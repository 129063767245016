<template>
  <div>
    <div class="h-[120px] flex justify-center">
      <img
        src="../assets/icons/turbomedic-logo__.svg"
        class="w-40 md:w-52"
        alt=""
      />
    </div>

    <div
      class="min-h-[calc(100vh-120px)] flex flex-col justify-center items-center space-y-2"
    >
      <div
        class="w-fit bg-BlueChalk p-6 rounded-full flex justify-center items-center"
      >
        <ErrorIcon />
      </div>
      <h1 class="text-lg md:text-2xl font-semibold">Error 404</h1>
      <p class="text-OlsoGrey">
        We could not find the page you were looking for.
      </p>
      <router-link
        to="/"
        class="text-ResolutionBlue font-semibold hover:underline transition-all duration-500"
        >Back to Home</router-link
      >
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ErrorIcon from "@/components/icons/ErrorIcon.vue";

export default defineComponent({
  name: "PageNotFound",
  components: {
    ErrorIcon,
  },
});
</script>
