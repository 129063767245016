<template>
  <div
    class="fixed top-0 left-0 z-[60] h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar"
  >
    <div
      class="w-full max-w-[600px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
    >
      <div>
        <div class="flex items-center justify-between mb-12">
          <div class="space-y-2.5">
            <p
              class="text-sm md:text-xl font-semibold cursor-pointer text-Charcoal"
            >
              Disable 2FA Via Email OTP
            </p>
          </div>
          <div class="self-start cursor-pointer" @click="closeDisableModal">
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>
        <div class="space-y-8 md:space-y-12">
          <div class="flex flex-col items-center justify-center space-y-3">
            <div>
              <label for="email_otp" class="text-MistBlue text-xs font-light"
                >Enter the OTP code sent to your email</label
              >
              <input
                class="text-center rounded-lg w-full text-3xl py-1 px-4 border border-BlueChalk text-DarkJungle focus:outline-none focus:border focus:ring-0 font-bold"
                type="text"
                name="text"
                v-model="email_otp"
              />
            </div>
            <div>
              <label for="email_otp" class="text-MistBlue text-xs font-light"
                >Enter one of your recovery code</label
              >
              <input
                class="text-center rounded-lg w-full text-3xl py-1 px-4 border border-BlueChalk text-DarkJungle focus:outline-none focus:border focus:ring-0 font-bold"
                type="text"
                name="recovery_code"
                v-model="recovery_code"
              />
            </div>
          </div>
          <div class="flex justify-center space-x-4">
            <button
              @click="closeDisableModal"
              type="button"
              class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
            >
              Cancel
            </button>
            <button
              @click="disable2FAViaEmail"
              class="bg-ResolutionBlue text-white text-sm font-semibold rounded-[44px] py-[13px] px-[52px] w-44"
            >
              <span v-if="isLoading" class="pl-3">
                <LoadingSpinner />
              </span>
              <span v-else>Disable</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { mapActions } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";

const closeDisableModal = inject("closeDisableModal");

const {
  "twoFactorAuthentication/disable2faViaEmailOtp": disable2faViaEmailOtp,
} = mapActions();

const isLoading = ref(false);
const email_otp = ref("");
const recovery_code = ref("");
// const showSuccessModal = ref(false);

const disable2FAViaEmail = async () => {
  try {
    isLoading.value = true;
    const response = await disable2faViaEmailOtp({
      email_otp: email_otp.value,
      recovery_code: recovery_code.value,
    });
    if (response) {
      toast.success("2FA Disabled Successfully", {
        autoClose: 3000,
      });
      console.log("Disabled 2fA wit Otp userData 11", response);
      closeDisableModal();
    }
  } catch (error) {
    console.log("Disabled 2fA wit Otp userData error", error);
    toast.error(error, {
      autoClose: 3000,
    });
  } finally {
    isLoading.value = false;
  }
};
</script>

<script>
export default {
  name: "DisableTwoFaViaEmailOtp",
};
</script>
