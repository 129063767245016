<template>
  <div
    class="w-[99px] bg-white py-1 rounded-sm border border-BlueChalk"
    @mouseleave="$emit('close')"
  >
    <div>
      <a
        :href="downloadablePdf"
        download
        class="flex items-center gap-2 py-1 px-3 text-OlsoGrey group hover:bg-DodgerBlue hover:text-white cursor-pointer"
      >
        <img
          src="../../../../assets/icons/download-dark.svg"
          alt="download icon"
          class="group-hover:hidden"
        />
        <img
          src="../../../../assets/icons/download-light.svg"
          alt="download icon"
          class="hidden group-hover:block"
        />
        <p class="text-xs font-semibold">Download</p>
      </a>

      <div v-if="!isAdmin">
        <div
          class="flex items-center gap-2 py-1 px-3 text-OlsoGrey group hover:bg-DodgerBlue hover:text-white cursor-pointer"
          @click="$emit('delete')"
        >
          <img
            src="../../../../assets/icons/delete-dark.svg"
            alt="delete icon"
            class="group-hover:hidden"
          />
          <img
            src="../../../../assets/icons/delete-light.svg"
            alt="delete icon"
            class="hidden group-hover:block"
          />
          <p class="text-xs font-semibold">Delete</p>
        </div>
      </div>

      <a
        :href="pdf"
        target="_blank"
        class="flex items-center gap-2 py-1 px-3 text-OlsoGrey group hover:bg-DodgerBlue hover:text-white cursor-pointer"
      >
        <img
          src="../../../../assets/icons/eye-icon-dark.svg"
          alt="eye icon"
          class="group-hover:hidden"
        />
        <img
          src="../../../../assets/icons/eye-icon-light.svg"
          alt="eye icon"
          class="hidden group-hover:block"
        />
        <p class="text-xs font-semibold">View</p>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "@/hooks/mapStore";

export default {
  name: "ReportActionBox",
  props: ["pdf"],
  emit: ["close", "delete"],
  setup(props) {
    const { "auth/getIsAdmin": isAdmin } = mapGetters();

    const downloadablePdf = props.pdf + "?download=true";

    return {
      isAdmin,
      downloadablePdf,
    };
  },
};
</script>
