<template>
  <div
    class="w-full max-w-[327px] rounded-[20px] border-[0.3px] border-RegentGrey py-10 px-8"
  >
    <h3 class="text-BalticSea text-xl font-bold mb-7">
      Metric & Imperial Units
    </h3>
    <div>
      <div
        class="flex justify-between items-center pb-3 border-b border-BlueChalk"
      >
        <p class="text-MistBlue text-sm text-semibold">Weight Unit</p>

        <div
          class="bg-BlueChalk py-1 px-1.5 rounded-[18px] text-xs text-center flex items-center gap-[10px]"
        >
          <button
            :disabled="loading"
            class="w-[34px] py-1.5 px-[10px] rounded-[18px]"
            :class="
              weight === 'kg' ? 'bg-ResolutionBlue text-white' : 'text-Gravel'
            "
            @click="handleSetWeight('kg')"
          >
            kg
          </button>
          <button
            :disabled="loading"
            class="w-[34px] py-1.5 px-[10px] rounded-[18px]"
            :class="
              weight === 'lb' ? 'bg-ResolutionBlue text-white' : 'text-Gravel'
            "
            @click="handleSetWeight('lb')"
          >
            lb
          </button>
        </div>
      </div>

      <div class="flex justify-between items-center pt-3">
        <p class="text-MistBlue text-sm text-semibold">Height Unit</p>

        <div
          class="bg-BlueChalk py-1 px-1.5 rounded-[18px] text-xs text-center flex items-center gap-[10px]"
        >
          <button
            :disabled="loading"
            class="w-[34px] py-1.5 px-[10px] rounded-[18px]"
            :class="
              height === 'ft' ? 'bg-ResolutionBlue text-white' : 'text-Gravel'
            "
            @click="handleSetHeight('ft')"
          >
            ft
          </button>
          <button
            :disabled="loading"
            class="w-[34px] py-1.5 px-[10px] rounded-[18px]"
            :class="
              height === 'cm' ? 'bg-ResolutionBlue text-white' : 'text-Gravel'
            "
            @click="handleSetHeight('cm')"
          >
            cm
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";

const { "user/getPatientProfile": getPatientProfile } = mapGetters();

const {
  "user/fetchPatientProfile": fetchPatientProfile,
  "user/changeSIUnit": changeSIUnit,
} = mapActions();

const weight = ref("kg");
const height = ref("cm");
const loading = ref(false);

onMounted(async () => {
  await fetchPatientProfile();
  weight.value = getPatientProfile.value.person.weight_units || "kg";
  height.value = getPatientProfile.value.person.height_units || "cm";
});

const handleSetWeight = async (value) => {
  const initialWeight = weight.value;
  try {
    weight.value = value;
    loading.value = true;
    await changeSIUnit({
      weight_units: weight.value,
    });
    toast.success("Metrics updated successfully", {
      autoClose: 1500,
    });
  } catch (error) {
    console.log("error:", error);
    weight.value = initialWeight;
    toast.error("Error updating metrics", {
      autoClose: 1500,
    });
  } finally {
    loading.value = false;
  }
};

const handleSetHeight = async (value) => {
  const initialHeight = height.value;
  try {
    height.value = value;
    loading.value = true;
    await changeSIUnit({
      height_units: height.value,
    });
    toast.success("Metrics updated successfully", {
      autoClose: 1500,
    });
  } catch (error) {
    console.log("error:", error);
    height.value = initialHeight;
    toast.error("Error updating metrics", {
      autoClose: 1500,
    });
  } finally {
    loading.value = false;
  }
};
</script>
