<template>
  <svg
    width="40"
    height="45"
    viewBox="0 0 40 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Calendar">
      <path
        id="Fill 1"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.5625 33.4029V16.7495H39.9375V33.5364C39.9375 40.4029 35.6153 44.6248 28.6999 44.6248H11.7785C4.9279 44.6248 0.5625 40.3154 0.5625 33.4029ZM11.4115 28.0219C10.4174 28.0678 9.5962 27.2781 9.55298 26.2741C9.55298 25.2678 10.331 24.4366 11.3251 24.3906C12.2976 24.3906 13.0972 25.1584 13.1188 26.1406C13.162 27.1491 12.384 27.9803 11.4115 28.0219ZM20.2929 28.0219C19.2988 28.0678 18.4775 27.2781 18.4343 26.2741C18.4343 25.2678 19.2123 24.4366 20.2064 24.3906C21.1789 24.3906 21.9785 25.1584 22.0001 26.1406C22.0433 27.1491 21.2653 27.9803 20.2929 28.0219ZM29.1105 36.0719C28.1164 36.05 27.3168 35.2188 27.3168 34.2125C27.2952 33.2063 28.0948 32.3772 29.0889 32.3553H29.1105C30.1262 32.3553 30.9475 33.1866 30.9475 34.2125C30.9475 35.2407 30.1262 36.0719 29.1105 36.0719ZM18.4343 34.2124C18.4775 35.2187 19.2988 36.0084 20.2929 35.9624C21.2653 35.9209 22.0433 35.0896 22.0001 34.0834C21.9785 33.099 21.1789 32.3312 20.2064 32.3312C19.2123 32.3771 18.4343 33.2062 18.4343 34.2124ZM9.53101 34.2124C9.57423 35.2187 10.3954 36.0084 11.3895 35.9624C12.362 35.9209 13.14 35.0896 13.0968 34.0834C13.0752 33.099 12.2756 32.3312 11.3031 32.3312C10.309 32.3771 9.53101 33.2062 9.53101 34.2124ZM27.3384 26.2523C27.3384 25.246 28.1164 24.4367 29.1105 24.4148C30.083 24.4148 30.861 25.2001 30.9042 26.1648C30.9258 27.171 30.1478 28.0023 29.1753 28.022C28.1812 28.0439 27.36 27.2782 27.3384 26.2742V26.2523Z"
        fill="#011D7E"
      />
      <path
        id="Fill 4"
        opacity="0.4"
        d="M0.569824 16.7491C0.597918 15.465 0.705973 12.9166 0.909115 12.0963C1.94644 7.48285 5.46901 4.5516 10.5043 4.13379H29.9973C34.9895 4.57348 38.5552 7.52441 39.5926 12.0963C39.7935 12.8947 39.9016 15.4629 39.9297 16.7491H0.569824Z"
        fill="#011D7E"
      />
      <path
        id="Fill 6"
        d="M12.167 10.9156C13.1178 10.9156 13.831 10.1959 13.831 9.23125V2.56156C13.831 1.59688 13.1178 0.875 12.167 0.875C11.2161 0.875 10.5029 1.59688 10.5029 2.56156V9.23125C10.5029 10.1959 11.2161 10.9156 12.167 10.9156Z"
        fill="#011D7E"
      />
      <path
        id="Fill 9"
        d="M28.333 10.9156C29.2622 10.9156 29.997 10.1959 29.997 9.23125V2.56156C29.997 1.59688 29.2622 0.875 28.333 0.875C27.3821 0.875 26.6689 1.59688 26.6689 2.56156V9.23125C26.6689 10.1959 27.3821 10.9156 28.333 10.9156Z"
        fill="#011D7E"
      />
    </g>
  </svg>
</template>
