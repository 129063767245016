import axios from "@/api";
import { io } from "socket.io-client";

const state = {
  isLoading: false,
  allNotifications: [],
  page: 1,
  perPage: 10,
  total: 0,
  socket: null,
};

const getters = {
  getAllNotifications: (state) => state.allNotifications,
  getNotificationsByType: (state) => (type) => {
    return state.allNotifications.filter(
      (notification) => notification.notification_type === type
    );
  },
};

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setNotifications(state, payload) {
    state.allNotifications = payload;
  },
  setPage(state, payload) {
    state.page = payload;
  },
  setPerPage(state, payload) {
    state.perPage = payload || 10;
  },
  setTotal(state, payload) {
    state.total = payload;
  },
  clearNotifications(state) {
    state.allNotifications = [];
  },
  addNotification(state, notification) {
    state.allNotifications.unshift(notification);
  },
  setSocket(state, socket) {
    state.socket = socket;
  },
};

const actions = {
  async fetchNotifications({ commit }, { type_filter, time_period }) {
    commit("setLoading", true);
    try {
      const response = await axios.get(
        `/data/patient/notifications?type_filter=${type_filter}&time_period=${time_period}`
      );
      commit("setNotifications", response.data.notifications);
      commit("setPage", response.data.page);
      commit("setPerPage", response.data.per_page);
      commit("setTotal", response.data.total);
    } catch (error) {
      throw error;
    } finally {
      commit("setLoading", false);
    }
  },
  initializeSocket({ commit }) {
    const socket = io("wss://dev.turbomedics.com/?EIO=4&transport=websocket");
    commit("setSocket", socket);

    socket.on("notification", (notification) => {
      console.log({ notification });
      commit("addNotification", notification);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
