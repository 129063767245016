export const data = {
  labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  datasets: [
    {
      label: 'Before meal',
      backgroundColor: '#4A3AFF',
      data: [70, 105, 10, 78, 175, 60, 20, 74, 170],
      borderColor: '#4A3AFF',
      borderWidth: '1',
      borderJoinStyle: 'round'
    },
     {
      label: 'After meal',
      backgroundColor: '#00DF82',
      data: [0, 45, 55, 20, 110, 170, 90, 140, 79],
      borderColor: '#00DF82',
      borderWidth: '1',
      borderJoinStyle: 'round',
      fill: false
    },
  ]
}

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#667085",
        },
      },
      y: {
        title: {
          display: true,
        },
        beginAtZero: true,
        max: 180,
        grid: {
          color: "#F2F4F7",
          display: true,
        },
        ticks: {
          color: "#667085",
          stepSize: 180 / 5,
        },

}
  }
}
