<template>
  <div class="w-full space-y-6">
    <div class="space-y-6 md:w-full lg:w-[70%] xl:w-[40%]">
      <div
        class="flex flex-row items-center justify-between border rounded-md px-2 py-2.5"
      >
        <div class="flex items-center w-full">
          <img src="../../../../assets/icons/Search.svg" alt="" />
          <input
            v-model="searchKeyword"
            @input="handleSearch"
            @focus="inputFocus = true"
            @blur="inputFocus = false"
            type="text"
            name=""
            id=""
            class="bg-transparent w-full focus:outline-0 px-3 py-1 text-sm"
            placeholder="Search for patient by username"
          />
        </div>
      </div>

      <div v-if="searchKeyword" class="text-sm">
        <p v-if="searching" class="border p-4 rounded-2xl h-52">Searching...</p>
        <div v-else-if="searchResults?.length > 0">
          <div class="border p-4 rounded-2xl h-52 overflow-auto space-y-1">
            <div v-for="(result, index) in searchResults" :key="index">
              <div
                class="flex items-center cursor-pointer hover:bg-WhiteLilac hover:rounded-lg p-1"
                @click="openAccountLinkingModal()"
              >
                <div class="flex-shrink-0 w-7 h-7">
                  <img
                    class="w-full h-full rounded-full object-cover"
                    :src="
                      result.person?.avatar_url || result.avatar_url || noAvatar
                    "
                    alt="profile picture"
                  />
                </div>
                <div class="ml-3">
                  <p class="font-bold text-DavyGrey whitespace-no-wrap">
                    {{ result.person?.full_name || result.name }}
                  </p>
                </div>
              </div>

              <AccountLinkingModal
                v-show="showAccountLinkingModal"
                :result="result"
              />
            </div>
          </div>
        </div>
        <div
          v-else-if="!searching && searchResults?.length === 0"
          class="border p-4 rounded-2xl h-52"
        >
          No patient found.
        </div>
      </div>
    </div>

    <div class="space-y-4 w-full h-full">
      <div v-if="!isFetchingPatients && getPatientRequests" class="w-full">
        <div
          v-if="getPatientRequests?.connected?.length > 0"
          class="border rounded-3xl px-4 py-8"
        >
          <div class="flex items-center justify-between">
            <div class="flex items-center gap-x-1.5">
              <p class="font-semibold text-lg md:text-xl">Linked Accounts</p>
              <span
                v-if="getPatientRequests?.connected"
                class="bg-ResolutionBlue text-white w-5 h-5 text-xs rounded-full flex justify-center items-center"
                >{{ getPatientRequests?.connected?.length }}</span
              >
            </div>
          </div>

          <div class="w-full py-6 overflow-x-scroll custom-scrollbar">
            <table class="w-full">
              <thead>
                <tr>
                  <th
                    class="min-w-[100px] py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  >
                    Name
                  </th>

                  <th
                    class="min-w-[100px] pl-5 py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  >
                    Relationship
                  </th>
                  <th
                    class="min-w-[200px] pl-5 py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  >
                    Date
                  </th>
                  <th
                    class="min-w-[100px] pl-5 py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  >
                    Time
                  </th>
                  <th
                    class="min-w-[100px] pl-5 py-3 border-b border-gray-200 text-left text-xs font-bold text-[#25282B]"
                  ></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  class="mb-4 font-light"
                  v-for="account in getPatientRequests?.connected"
                  :key="account.id"
                >
                  <LinkedAccounts :account="account" />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div v-else class="h-20 flex justify-center items-center">
        <img :src="loader" alt="loader icon" class="animate-spin" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, provide, onMounted } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import LinkedAccounts from "./LinkedAccounts.vue";
import AccountLinkingModal from "./AccountLinkingModal.vue";
import loader from "@/assets/icons/loading-01.svg";

const {
  "user/searchPatient": searchPatient,
  "accountConnection/fetchPatientRequests": fetchPatientRequests,
} = mapActions();

const { "accountConnection/getPatientRequests": getPatientRequests } =
  mapGetters();

const searchKeyword = ref("");
const searchResults = ref([]);
const inputFocus = ref(false);
const selectedPatient = ref(null);
const showAccountLinkingModal = ref(false);
const isFetchingPatients = ref(false);
const isLoading = ref(false);
const searching = ref(false);

const noAvatar =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

// open account linking modal
const openAccountLinkingModal = () => {
  showAccountLinkingModal.value = true;
};

// close account linking modal
const closelinkAccountModal = () => {
  showAccountLinkingModal.value = false;
};

provide("closelinkAccountModal", closelinkAccountModal);

let timerId;

const handleSearch = async () => {
  try {
    searching.value = true;
    clearTimeout(timerId);
    timerId = setTimeout(async () => {
      const response = await searchPatient({
        username: searchKeyword.value,
      });
      console.log("API Response:", response.results);
      searchResults.value = response.results;
      searching.value = false;
    }, 1000);
  } catch (error) {
    console.error("Error searching users:", error);
    searching.value = false;
  }
};

const getAllRequests = async () => {
  isLoading.value = true;
  try {
    await fetchPatientRequests();
  } catch (error) {
    console.log("err", error);
  } finally {
    isLoading.value = false;
  }
};

getAllRequests();

onMounted(async () => {
  isFetchingPatients.value = true;
  await fetchPatientRequests();
  isFetchingPatients.value = false;
});

const updateFullName = (event) => {
  relationship.value = event.target.value;
};
</script>
