import store from "@/store";

export default async function checkAuthentication() {
  const isLoggedIn = store.state.auth.isLoggedIn;
  let user = null;
  let admin = false;

  if (isLoggedIn) {
    user = store.state.user.user;
    admin = store.state.auth.isAdmin;
  } else {
    const response = await store.dispatch("auth/isAuthenticated");
    user = response;
    if (response) {
      admin = response.user.roles[0] === "admin";
    }
  }

  return {
    user,
    admin,
  };
}
