<template>
  <div
    :class="[
      profileStatusClasses.bgClass,
      profileStatusClasses.textClass,
      profileStatusClasses.borderClass,
    ]"
    class="flex items-center justify-center bg-BlueChalk text-ResolutionBlue text-center font-light py-3 w-full rounded-md"
  >
    <p class="w-[92%]">
      Your profile is
      <span :class="profileStatusClasses.textClass" class="font-bold"
        >{{ profilePercentage }}%
      </span>
      complete.
      <router-link
        to="/patient/settings/account-information"
        :class="profileStatusClasses.linkClass"
        >Complete your account setup and explore Turbomedic’s
        features</router-link
      >
    </p>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { mapGetters } from "@/hooks/mapStore";

const { "user/getProfilePercentage": profilePercentage } = mapGetters();

const profileStatusClasses = computed(() => {
  if (profilePercentage.value <= 65) {
    return {
      bgClass: "bg-red-500/90",
      textClass: "text-white",
      borderClass: "border-red-700",
      linkClass: "border-b-[0.1px] border-white",
    };
  }
  return {
    bgClass: "bg-BlueChalk",
    textClass: "text-ResolutionBlue",
    borderClass: "border-ResolutionBlue",
    linkClass: "border-b-[0.1px] border-ResolutionBlue",
  };
});
</script>
