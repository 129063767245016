<template>
  <div
    class="absolute left-0 top-full mt-3 z-10 bg-white w-full h-[102px] py-4 px-6 rounded-lg border border-BluishGrey flex flex-col gap-[10px] overflow-y-scroll no-scrollbar"
  >
    <div
      v-if="devices?.length > 0"
      v-for="device in devices"
      :key="device.id"
      @click="selectDevice(device)"
      class="flex items-center gap-4 cursor-pointer"
    >
      <div class="w-[30px] h-[30px] flex justify-center items-center">
        <img
          src="../../../../../assets/icons/small_device_image1.svg"
          :alt="device.name"
        />
      </div>

      <p class="text-DarkJungleGreen text-sm">
        {{ device.category }}
      </p>
    </div>
    <div v-else class="flex justify-center items-center h-full">
      <p class="text-DarkJungleGreen text-xl font-medium text-center">
        No devices found
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FacilityDeviceDropdown",
  props: {
    devices: {
      type: Array,
      default: [],
    },
  },
  emits: ["setDevice"],
  setup(_, { emit }) {
    const selectDevice = (value) => {
      emit("setDevice", value);
    };

    return {
      selectDevice,
    };
  },
};
</script>
