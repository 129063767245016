<template>
  <div
    class="fixed top-0 left-0 z-[60] h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar"
  >
    <div
      class="w-full max-w-[600px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
    >
      <div>
        <div class="flex items-center justify-between mb-12">
          <div class="space-y-2.5">
            <p
              class="text-sm md:text-xl font-semibold cursor-pointer text-Charcoal"
            >
              Setup Email OTP Authentication
            </p>
            <p class="text-GreyChateau text-sm">
              Enter the six digit code sent to your email ...
            </p>
          </div>
          <div class="self-start cursor-pointer" @click="closeModal">
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>
        <div class="space-y-8 md:space-y-12">
          <div class="flex flex-col justify-center space-x-4">
            <div class="flex justify-center">
              <input
                class="text-center rounded-xl w-72 text-4xl py-2 px-4 border border-BlueChalk text-DarkJungle focus:outline-none focus:border focus:ring-0 font-bold"
                type="number"
                name="number"
                v-model="email_otp"
              />
            </div>
          </div>
          <div class="flex justify-center space-x-4">
            <button
              type="button"
              class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
              @click="closeModal"
            >
              Cancel
            </button>
            <button
              @click="enable2faViaEmail"
              class="bg-ResolutionBlue text-white text-sm font-semibold rounded-[44px] py-[13px] px-[52px] w-44"
            >
              <span v-if="isLoading" class="pl-3">
                <LoadingSpinner />
              </span>
              <span v-else>Enable</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="showModal_2">
    <div
      class="fixed top-0 left-0 z-[60] h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar"
    >
      <div
        class="w-full max-w-[600px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
      >
        <div>
          <div class="flex items-center justify-between mb-12">
            <div class="space-y-2.5">
              <p
                class="text-sm md:text-xl font-semibold cursor-pointer text-Charcoal"
              >
                Setup Email OTP Authentication
              </p>
            </div>
            <div class="self-start cursor-pointer" @click="closeModal_2">
              <span
                class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
              ></span>
            </div>
          </div>
          <div class="space-y-8 md:space-y-12">
            <div
              class="bg-Honeydew flex justify-center items-center rounded-md space-x-4 border border-dashed font-semibold border-NapierGreen w-full py-4"
            >
              <img
                src="../../../../../assets/icons/email_success_icon.svg"
                alt=""
              />
              <p class="text-NapierGreen text-sm">
                2FA enabled successfully via email OTP
              </p>
            </div>

            <div
              class="flex flex-col items-center justify-center text-center text-MistBlue"
            >
              <p class="font-semibold text-sm md:text-base">Recovery Code</p>
              <p class="font-lighter text-[10px] md:text-xs md:max-w-sm">
                In case you lose the ability to use two-factor authentication,
                any of these codes can be used to disable two-factor
                authentication on your account
              </p>
            </div>
            <div
              class="bg-Porcelain border-[0.1px] border-GreyChateau rounded-md max-h-44 overflow-y-scroll"
            >
              <div
                class="flex w-full py-1"
                v-for="(otp_recovery_code, index) in otp_recovery_codes"
                :key="index"
              >
                <p
                  class="flex justify-center text-DavyGrey_2 font-lighter text-sm w-[92%]"
                >
                  {{ otp_recovery_code }}
                </p>
                <div class="w-[8%] flex justify-center">
                  <img
                    src="../../../../../assets/icons/content_copy.svg"
                    alt=""
                    class="cursor-pointer"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref } from "vue";
import { mapActions } from "@/hooks/mapStore";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import TwoFaEnabledViaEmailSuccessModal from "./TwoFAEnabledViaEmailSuccessModal.vue";
import { toast } from "vue3-toastify";

const { "twoFactorAuthentication/enable2faViaEmailOtp": enable2faViaEmailOtp } =
  mapActions();

const closeModal = inject("closeModal");

const email_otp = ref("");
const isLoading = ref(false);
const otp_recovery_codes = ref([]);
const showModal = ref(false);
const showModal_2 = ref(false);

const enable2faViaEmail = async () => {
  try {
    isLoading.value = true;
    const response = await enable2faViaEmailOtp({
      email_otp: email_otp.value,
    });
    if (response) {
      showModal_2.value = true;
      otp_recovery_codes.value = response.otp_recovery_codes;
      console.log("response uumm", response);
    }
  } catch (error) {
    toast.error("wrong OTP", {
      autoClose: 3000,
    });
    console.error("enable2faViaEmail in comp", error);
    throw error;
  } finally {
    isLoading.value = false;
  }
};

const closeModal_2 = () => {
  showModal_2.value = false;
  closeModal();
};
</script>

<script>
export default {
  name: "EmailOtpModal",
};
</script>
