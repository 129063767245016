import { formatDistanceToNowStrict } from 'date-fns';

const formatRelativeTime = (date) => {
  let relativeTime = formatDistanceToNowStrict(new Date(date));
  relativeTime = relativeTime.replace("about ", "")
                             .replace(" hours", " hrs")
                             .replace(" minutes", " mins")
                             .replace(" seconds", " secs")
                             .replace(" hour", " hr")
                             .replace(" minute", " min")
                             .replace(" second", " sec");
  return relativeTime + " ago";
};

export default formatRelativeTime;
