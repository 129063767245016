import axios from "@/api";
import store from "@/store";

const state = {
  addBlog: null,
  blogPosts: null,
};

const getters = {
  getAddBlogPost: (state) => {
    return state.addBlog;
  },
  getBlogPosts: (state) => {
    return state.blogPosts;
  },
};

const actions = {
  // add new blog post
  async addBlogPost({ commit }, formData) {
    try {
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post("/blog/create", formData, config);
      console.log("store add blog res", response);
      commit("setAddBlogPost", response.data);
      return response.data;
    } catch (error) {
      console.error("add blog store error", error);
    }
  },

  // fetch all the blog posts
  async fetchBlogPosts({ commit }) {
    try {
      const response = await axios.get(`/blog/posts`);
      // console.log("blogs", response);
      commit("setblogPosts", response.data);
      return response.data;
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  },

  // update blog post title and author
  async updateBlogPostTitleAndAuthor(_, payload) {
    try {
      await axios.put(`/blog/post/update/${payload.id}`, {
        title: payload.title,
        author: payload.author,
      });
    } catch (error) {
      throw error;
    }
  },

  // update blog post body
  async updateBlogPostBody(_, payload) {
    try {
      const formData = new FormData();
      formData.append("body", payload.body);
      await axios.patch(`/blog/post/update/body/${payload.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      throw error;
    }
  },

  // update blog post image
  async updateBlogPostImage(_, payload) {
    try {
      const formData = new FormData();
      if (payload.image) {
        formData.append("image", payload.image);
      }
      await axios.patch(`/blog/post/update/image/${payload.id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      throw error;
    }
  },

  // delete blog post
  async deleteBlogPost(_, payload) {
    try {
      await axios.delete(`/blog/delete/${payload.id}`);
    } catch (error) {
      throw error;
    }
  },
};

const mutations = {
  setAddBlogPost(state, payload) {
    state.addBlog = payload;
  },

  setblogPosts(state, payload) {
    state.blogPosts = payload;
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  getters,
  state,
};
