<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10 space-y-6"
    >
      <p class="text-ResolutionBlue font-bold text-xl lg:text-2xl">Devices</p>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-6 wfull xl:w-[80%]">
        <div class="border bg-WhiteLilac rounded-2xl p-4">
          <div
            class="space-y-3.5"
            v-if="getPatientProfile?.medical_devices?.length > 0"
          >
            <div
              v-for="(device, index) in getPatientProfile?.medical_devices"
              :key="index"
              class="bg-white py-[10px] px-[14px] rounded-lg flex items-center gap-5"
            >
              <div
                class="bg-white rounded-sm border border-WhiteLilac py-2 px-1.5 flex justify-center items-center"
              >
                <img
                  src="../../../assets/icons/small_device_image1.svg"
                  alt=""
                />
              </div>

              <div>
                <p class="text-BluishGrey text-xs">Blood Glucose</p>
                <p class="text-OlsoGrey text-sm font-bold">
                  4G Blood Glucose Monitor
                </p>
              </div>
            </div>
          </div>

          <div class="flex flex-row justify-center items-center" v-else>
            <div
              class="flex flex-col justify-center items-center py-12 md:py-20 space-y-5"
            >
              <div
                class="w-fit bg-BlueChalk px-6 py-5 rounded-full flex justify-center items-center"
              >
                <DocumentIcon class="" />
              </div>
              <p class="text-Charcoal text-sm">You have no device yet</p>
            </div>
          </div>
        </div>

        <div class="w-full rounded-2xl">
          <div class="w-full rounded-2xl">
            <div class="w-full h-full rounded-t-2xl">
              <img
                src="../../../assets/icons/shop-device-img.svg"
                class="w-full rounded-t-2xl"
                alt=""
              />
            </div>
            <div
              class="bg-shop-device-bg-lines bg-no-repeat bg-cover rounded-b-2xl"
            >
              <div class="flex items-center justify-between px-12 py-4">
                <p class="text-white font-semibold text-base md:text-lg">
                  Shop our devices
                </p>
                <a
                  href="https://paystack.shop/turbomedics-store"
                  target="_blank"
                  class="bg-white rounded-full px-7 py-4 text-ResolutionBlue font-medium text-xs md:text-sm hover:bg-DodgerBlue hover:text-white transition-all duration-300"
                  >Visit Store Now</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import DocumentIcon from "@/components/icons/DocumentIcon.vue";

const { "user/getPatientProfile": getPatientProfile } = mapGetters();

const { "user/fetchPatientProfile": fetchPatientProfile } = mapActions();

onMounted(() => {
  fetchPatientProfile();
});
</script>
