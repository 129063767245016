<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10">
      <div
        class="calendly-inline-widget"
        data-url="https://calendly.com/turbomedics/30min"
        style="min-width: 320px; height: 630px"
      ></div>
      <!-- <div class="w-full border px-2 md:px-5 py-6 rounded-xl">
          <div
            class="flex flex-col space-y-6 md:space-y-0 md:flex-row md:justify-between items-center mb-4"
          >
            <p class="font-urbanist-regular font-bold">My Appointments</p>
          </div>
  
          <div
            v-if="isLoading"
            class="w-full h-64 flex justify-center items-center"
          >
            <img
              src="../../../assets/icons/loading-01.svg"
              alt="loading icon"
              class="animate-spin"
            />
          </div>
  
          <div
            v-else-if="
              !isLoading && getPatientAppointments?.length > 0
            "
          >
            <div class="overflow-x-auto">
              <table class="w-full">
                <thead>
                  <tr>
                    <th
                      class="min-w-[100px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Date
                    </th>
  
                    <th
                      class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Health Center
                    </th>
                    <th
                      class="min-w-[60px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      City
                    </th>
                    <th
                      class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Address
                    </th>
                    <th
                      class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Health Center Phone Number
                    </th>
                    <th
                      class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Appointment Type
                    </th>
                    <th
                      class="min-w-[130px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Reason for Appointment
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="apointment in getPatientAppointments"
                    :key="apointment.id"
                  >
                    <td
                      class="min-w-[100px] py-3 border-t border-gray-200 text-DavyGrey text-sm"
                    >
                      {{ apointment?.health_centre?.created_at }}
                    </td>
                    <td
                      class="min-w-[100px] py-3 border-t border-gray-200 text-DavyGrey text-sm"
                    >
                      {{ apointment?.health_centre?.name }}
                    </td>
                    <td
                      class="min-w-[60px] py-3 border-t border-gray-200 text-DavyGrey text-sm"
                    >
                      {{ apointment?.health_centre?.city }}
                    </td>
                    <td
                      class="min-w-[100px] py-3 border-t border-gray-200 text-DavyGrey text-sm cursor-pointer"
                      :title="apointment?.health_centre?.address"
                    >
                      {{
                        apointment?.health_centre?.address?.substring(0, 30) +
                        "..."
                      }}
                    </td>
                    <td
                      class="min-w-[100px] py-3 border-t border-gray-200 text-DavyGrey text-sm cursor-pointer"
                    >
                      {{ apointment?.health_centre?.phone }}
                    </td>
                    <td
                      class="min-w-[100px] py-3 border-t border-gray-200 text-DavyGrey text-sm"
                    >
                      {{ apointment?.type }}
                    </td>
                    <td
                      class="min-w-[100px] py-3 border-t border-gray-200 text-DavyGrey text-sm"
                    >
                      {{ apointment?.reason_for_appointment }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else class="w-full h-64 flex justify-center items-center">
            <div
              class="flex flex-col justify-center items-center py-16 space-y-4"
            >
              <div
                class="w-fit bg-WhiteLilac p-6 rounded-full flex justify-center items-center"
              >
                <AppointmentIcon class="" />
              </div>
              <p class="text-ResolutionBlue font-semibold">
                You have no upcoming appointments
              </p>
              <p class="text-Charcoal">
                Schedule appointment with doctors at your convenience
              </p>
              <div class="text-center">
                <button
                  class="bg-ResolutionBlue py-2 px-12 md:py-[13px] md:px-[52px] rounded-[44px] text-white text-sm font-semibold hover:bg-DodgerBlue hover:text-white transition-all duration-300"
                  @click="openAppointmentModal"
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div> -->
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

onMounted(() => {
  let Script = document.createElement("script");
  Script.setAttribute(
    "src",
    "https://assets.calendly.com/assets/external/widget.js"
  );
  document.head.appendChild(Script);
});
// import { ref, onMounted, inject } from "vue";
// import AppointmentIcon from "@/components/icons/AppointmentIcon.vue";
// import { mapActions, mapGetters } from "@/hooks/mapStore";

// const { "appointment/fetchPatientAppointments": fetchPatientAppointments } =
//   mapActions();
// const { "appointment/getPatientAppointments": getPatientAppointments } =
//   mapGetters();

// const openAppointmentModal = inject("openAppointmentModal");
// const isLoading = ref(false);

// const getAllAppointments = async () => {
//   isLoading.value = true;
//   try {
//     await fetchPatientAppointments();
//   } catch (error) {
//     console.log("err", error);
//   } finally {
//     isLoading.value = false;
//   }
// };

// getAllAppointments();

// onMounted(() => {
//   fetchPatientAppointments();
//   console.log("getPatientAppointments 22", getPatientAppointments.value);
// });
// mounted() {
//   let Script = document.createElement("script");
//   Script.setAttribute(
//     "src",
//     "https://assets.calendly.com/assets/external/widget.js"
//   );
//   document.head.appendChild(Script);
// },
</script>

<script>
export default {
  name: "Appointment",
};
</script>
