<template>
  <svg
    width="52"
    height="53"
    viewBox="0 0 52 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="error" clip-path="url(#clip0_6494_6158)">
      <path
        id="Vector"
        d="M25.9997 4.83337C14.0397 4.83337 4.33301 14.54 4.33301 26.5C4.33301 38.46 14.0397 48.1667 25.9997 48.1667C37.9597 48.1667 47.6663 38.46 47.6663 26.5C47.6663 14.54 37.9597 4.83337 25.9997 4.83337ZM28.1663 37.3334H23.833V33H28.1663V37.3334ZM28.1663 28.6667H23.833V15.6667H28.1663V28.6667Z"
        fill="#F43F5E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6494_6158">
        <rect
          width="52"
          height="52"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
