<template>
  <div
    class="bg-ResolutionBlue p-4 h-fit rounded-xl bg-wave-lines bg-center bg-no-repeat bg-cover"
  >
    <div>
      <img
        src="../../../../assets/images/dashboard_nurse.png"
        alt="nurse"
        class="mx-auto"
      />
    </div>

    <div class="text-center my-4">
      <h3 class="text-white text-2xl font-bold">Upgrade to RPM</h3>
      <router-link to="#" class="text-white text-base"
        >to access all features</router-link
      >
    </div>

    <div class="text-center">
      <button
        class="bg-white py-2 px-5 md:py-3 md:px-6 rounded-[44px] text-ResolutionBlue text-sm font-semibold"
      >
        Coming soon
      </button>
    </div>
  </div>
</template>
