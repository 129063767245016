<template>
  <form @submit.prevent="handleSubmit" class="mt-36">
    <div class="text-center mb-3">
      <h1 class="text-black text-2xl font-bold mb-2">Create new password</h1>
      <p class="text-OlsoGrey text-base max-w-[344px]">
        Create a strong and secure password for signing in to your Turbomedics
        account.
      </p>
    </div>

    <div class="mb-3.5">
      <div class="flex justify-between items-center pt-4">
        <label for="password" class="text-BluishGrey text-sm">Password</label>

        <div
          class="cursor-pointer relative"
          :class="
            showPassword &&
            'before:absolute before:w-full before:h-[1px] before:bg-BluishGrey before:top-1/2 before:-translate-y-1/2 before:left-0 before:rotate-45'
          "
          @click="showPassword = !showPassword"
        >
          <img src="../../../assets/icons/password_eye.svg" alt="eye icon" />
        </div>
      </div>

      <input
        :type="showPassword ? 'text' : 'password'"
        name="password"
        id="password"
        class="w-full py-1 focus:outline-none border-b border-BlueChalk text-DarkJungle text-sm font-bold"
        v-model="password"
      />
      <p class="text-red-500 text-[10px] mt-1">{{ passwordError }}</p>
    </div>

    <div class="mb-7">
      <div class="flex justify-between items-center pt-4">
        <label for="password2" class="text-BluishGrey text-sm"
          >Confirm Password</label
        >

        <div
          class="cursor-pointer relative"
          :class="
            showPassword2 &&
            'before:absolute before:w-full before:h-[1px] before:bg-BluishGrey before:top-1/2 before:-translate-y-1/2 before:left-0 before:rotate-45'
          "
          @click="showPassword2 = !showPassword2"
        >
          <img src="../../../assets/icons/password_eye.svg" alt="eye icon" />
        </div>
      </div>

      <input
        :type="showPassword2 ? 'text' : 'password'"
        name="password2"
        id="password2"
        class="w-full py-1 focus:outline-none border-b border-BlueChalk text-DarkJungle text-sm font-bold"
        v-model="password2"
      />
      <p class="text-red-500 text-[10px] mt-1">{{ passwordError2 }}</p>
    </div>

    <div class="mb-7">
      <button
        class="w-full custom-button font-bold bg-ResolutionBlue rounded-[44px] py-3 px-1 md:py-4 md:px-2 duration-500 text-sm text-white"
        :class="!isLoginDisabled && 'hover:bg-DodgerBlue'"
        :disabled="isLoginDisabled || isLoading"
      >
        <span v-if="isLoading">
          <LoadingSpinner />
        </span>
        <span v-else>Create Password</span>
      </button>
    </div>

    <div class="text-center">
      <p class="text-MountainMist text-xs font-semibold">
        Remember your old password?
        <router-link to="/auth/sign-in" class="text-ResolutionBlue">
          Sign in now</router-link
        >
      </p>
    </div>
  </form>
</template>

<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { mapActions } from "@/hooks/mapStore";
import useCheckPassword from "@/composables/useCheckPassword";
import { toast } from "vue3-toastify";

export default {
  name: "CreateNewPassword",
  components: {
    LoadingSpinner,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const { "auth/createNewPassword": createNewPassword } = mapActions();

    const password = ref("");
    const password2 = ref("");
    const showPassword = ref(false);
    const showPassword2 = ref(false);
    const isLoading = ref(false);
    const passwordError = ref("");
    const passwordError2 = ref("");

    const isLoginDisabled = useCheckPassword(password, passwordError);

    const handleSubmit = async () => {
      if (password.value !== password2.value) {
        passwordError2.value = "Passwords do not match";
      } else {
        isLoading.value = true;
        try {
          passwordError2.value = "";
          await createNewPassword({
            data: { password: password.value, password2: password2.value },
            email: route.query.email,
            token: route.query.token,
          });
          isLoading.value = false;
          password.value = "";
          password2.value = "";
          router.push("/auth/password-reset-confirmed");
        } catch (error) {
          // console.log(error.response.data.message);
          isLoading.value = false;
          toast.error("Password reset failed", {
            autoClose: 3000,
          });
        }
      }
    };

    return {
      password,
      password2,
      showPassword,
      showPassword2,
      isLoading,
      passwordError,
      passwordError2,
      isLoginDisabled,
      handleSubmit,
    };
  },
};
</script>
