export const procedures = [
  "Others (Specify in notes below)",
  "AFB Studies",
  "Arthrogram",
  "Blood Culture",
  "Blood Film For Malaria Parasite",
  "Blood Group",
  "Blood Group And Crossmatch",
  "Bone Density Scan",
  "CBC",
  "CT/CAT Scan",
  "Cytology",
  "DNA",
  "Electrolytes, Urea, And Creatinine",
  "ESR",
  "Glucose",
  "Hb Electrophoresis",
  "Hba1c",
  "Hbsag",
  "HCV",
  "HIV",
  "Lipid Profile",
  "Liver Function",
  "Mammogram",
  "MRA",
  "MRI",
  "Myelogram",
  "Phosphorus",
  "Pregnancy",
  "PT/INR",
  "Stool Microscopy",
  "Surgicals",
  "Syphilis",
  "Thyroid Function",
  "Ultrasound",
  "Urinalysis",
  "Urine Microscopy",
  "X-Ray",
];

export const bodyParts = [
  "Others (Specify in notes below)",
  "Abdomen",
  "Back",
  "Bladder",
  "Blood Vessels",
  "Brain",
  "Breast",
  "Chest",
  "Face",
  "Foot",
  "Forearm",
  "Head",
  "Heart",
  "Joints",
  "Kidneys",
  "Knee",
  "Leg",
  "Liver",
  "Lower Back",
  "Lower Limb",
  "Lungs",
  "Muscles",
  "Neck",
  "Pelvis",
  "Sinus",
  "Spine",
  "Stomach",
  "Teeth",
  "Thigh",
  "Upper Arm",
  "Upper Limb",
  "Uterus",
];
