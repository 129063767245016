import axios from "@/api";

const state = {
  newDevice: null,
  allDevices: [],
};

const getters = {
  setAddNewDevice: (state) => {
    return state.newDevice;
  },
  setAllDevices: (state) => {
    return state.allDevices;
  },
};

const mutations = {
  setAddNewDevice(state, newDeviceData) {
    state.newDevice = newDeviceData;
  },
  setAllDevices(state, devices) {
    state.allDevices = devices;
  },
};

const actions = {
  async addNewDevice({ commit }, deviceDetails) {
    try {
      const response = await axios.post("/data/medical_devices", deviceDetails);
      commit("setAddNewDevice", response.data);
      // console.log("New device:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error adding new device:", error);
      throw error;
    }
  },
  async getAllDevices({ commit, rootState }) {
    const isAdmin = rootState.auth.isAdmin;

    if (isAdmin) {
      try {
        const response = await axios.get("/data/medical_devices");
        commit("setAllDevices", response.data);
      } catch (error) {
        throw error;
      }
    }
  },
  async linkDevice(_, payload) {
    try {
      const response = await axios.post(
        `/data/medical_device/link/${payload.patientId}`,
        {
          imei: payload.imei,
          sn: payload.sn,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  async unlinkDevice(_, payload) {
    try {
      const response = await axios.delete(
        `/data/medical_device/delete/${payload}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
