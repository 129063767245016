<template>
  <ModalWrapper>
    <div
      class="bg-white w-full max-w-[724px] p-8 md:p-16 rounded-[48px] relative"
    >
      <div class="mb-9">
        <img
          src="../../../../../assets/icons/active-icon.svg"
          alt="active icon"
          class="mx-auto"
        />
      </div>

      <div class="text-center mb-7">
        <h3 class="text-DarkJungle text-2xl font-bold mb-2">Success</h3>
        <p class="text-MistBlue text-sm">Test submitted successfully</p>
      </div>

      <div class="text-center">
        <button
          class="bg-ResolutionBlue text-white text-sm font-semibold py-[13px] px-[52px] rounded-[44px]"
          @click="goToDashboard"
        >
          Return to Dashboard
        </button>
      </div>
    </div>
  </ModalWrapper>
</template>

<script>
import { useRouter } from "vue-router";
import ModalWrapper from "./ModalWrapper.vue";
import { inject } from "vue";

export default {
  name: "TestSuccessfulModal",
  components: {
    ModalWrapper,
  },
  setup() {
    const router = useRouter();

    const closeTestSuccessfulModal = inject("closeTestSuccessfulModal");

    const goToDashboard = () => {
      closeTestSuccessfulModal();
      router.push("/admin/dashboard");
    };
    return {
      goToDashboard,
    };
  },
};
</script>
