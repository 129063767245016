<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="fi:bar-chart-2">
      <path
        id="Vector"
        d="M3.64014 12.8511V9.27661"
        stroke="#45464E"
        stroke-width="1.78723"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M10.7891 12.8511V6.89362"
        stroke="#45464E"
        stroke-width="1.78723"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M7.21436 12.8511V3.31915"
        stroke="#45464E"
        stroke-width="1.78723"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
