<template>
  <div
    class="absolute left-0 top-full z-10 mt-3 bg-white w-full min-h-fit max-h-[145px] py-4 px-5 rounded-lg border border-BluishGrey overflow-y-scroll no-scrollbar"
  >
    <ul>
      <li
        v-for="(reason, index) in reasons"
        :key="index"
        class="text-DarkJungleGreen text-xs md:text-sm py-1.5 px-2.5 rounded hover:bg-BlueChalk transition-colors duration-300 cursor-pointer"
        @click="$emit('selectReason', reason.value)"
      >
        {{ reason.reason }}
      </li>
    </ul>
  </div>
</template>

<script setup>
defineEmits(["selectReason"]);

const reasons = [
  {
    reason: "Free Testing",
    value: "Free Testing",
  },
  {
    reason: "Free Testing and Consultation",
    value: "Free Testing and Consultation",
  },
  {
    reason: "IT Issues",
    value: "IT Issues",
  },
  {
    reason: "Registration and Onboarding",
    value: "Registration and Onboarding",
  },
  {
    reason: "General Consultation",
    value: "General Consultation",
  },
  {
    reason: "Others (Please specify)",
    value: "",
  },
];
</script>
