<template>
  <div>
    <slot></slot>

    <AppointmentModal
      v-show="showAppointmentModal"
      :appointmentValues="appointmentModalVal"
    />

    <ReportAndStatisticsModal v-show="showReportAndStatisticsModal" />
    <ReportNoteModal v-show="showReportNoteModal" :note="reportNote" />
    <FileUploadSuccessfulModal v-show="showFileUploadSuccessfulModal" />

    <SelectTestTypeModal v-show="showSelectTestTypeModal" />
    <SearchPatientModal v-show="showSearchPatientModal" />
    <TestCenterModal v-if="showTestModal" />
    <AddNewPatientModal v-show="showAddNewPatientModal" />
    <TestStepsModal v-show="showTestStepsModal" />
    <TestSuccessfulModal v-show="showTestSuccessfulModal" />

    <ViewBlogPost v-if="showBlogPostModal" :post="selectedPost" />
    <EditBlogPost v-if="showEditBlogModal" :post="selectedPost" />
    <DeleteBlogPost v-if="showDeleteBlogModal" :post="selectedPost" />
  </div>
</template>

<script setup>
import { ref, provide } from "vue";
import AppointmentModal from "./patient/appointment/AppointmentModal.vue";
import ReportAndStatisticsModal from "./patient/reportAndStatistics/ReportAndStatisticsModal.vue";
import ReportNoteModal from "./patient/reportAndStatistics/ReportNoteModal.vue";
import FileUploadSuccessfulModal from "./patient/reportAndStatistics/FileUploadSuccessfulModal.vue";
import SelectTestTypeModal from "./admin/testCenter/modals/SelectTestTypeModal.vue";
import SearchPatientModal from "./admin/testCenter/modals/SearchPatientModal.vue";
import TestCenterModal from "./admin/testCenter/modals/TestCenterModal.vue";
import AddNewPatientModal from "./admin/testCenter/modals/AddNewPatientModal.vue";
import TestStepsModal from "./admin/testCenter/modals/TestStepsModal.vue";
import TestSuccessfulModal from "./admin/testCenter/modals/TestSuccessfulModal.vue";
import ViewBlogPost from "./admin/blog/ViewBlogPost.vue";
import EditBlogPost from "./admin/blog/EditBlogPost.vue";
import DeleteBlogPost from "./admin/blog/DeleteBlogPost.vue";

// ref values

// Appointment modal
const showAppointmentModal = ref(false);
const appointmentModalVal = ref({
  type: "default",
  country: "",
  state: "",
  city: "",
  center: "",
  appointmentReason: "",
  address: "",
  date: "",
  time: "",
  appointmentId: "",
});

// Report and statistics modal
const showReportAndStatisticsModal = ref(false);

// Report note modal
const showReportNoteModal = ref(false);
const reportNote = ref("");

// File upload successful modal
const showFileUploadSuccessfulModal = ref(false);

// Test center modal
const selectedPatient = ref(null);
const showSelectTestTypeModal = ref(false);
const showSearchPatientModal = ref(false);
const showTestModal = ref(false);
const showAddNewPatientModal = ref(false);
const showTestStepsModal = ref(false);
const showTestSuccessfulModal = ref(false);
const currentPatientAndDevice = ref({
  patient: null,
  device: null,
});

// Blog post
const showBlogPostModal = ref(false);
const showEditBlogModal = ref(false);
const showDeleteBlogModal = ref(false);
const selectedPost = ref(null);

// Functions

// Appointment modal
const setAppointmentModalVal = (val) => {
  appointmentModalVal.value = val;
};

const openAppointmentModal = () => {
  showAppointmentModal.value = true;
};

const closeAppointmentModal = () => {
  showAppointmentModal.value = false;
};

// Appointment modal prop values
provide("appointmentModalVal", appointmentModalVal);
provide("setAppointmentModalVal", setAppointmentModalVal);

provide("appointmentModal", showAppointmentModal);
provide("openAppointmentModal", openAppointmentModal);
provide("closeAppointmentModal", closeAppointmentModal);

// Report and statistics modal
const openReportAndStatisticsModal = () => {
  showReportAndStatisticsModal.value = true;
};

const closeReportAndStatisticsModal = () => {
  showReportAndStatisticsModal.value = false;
};

provide("reportAndStatisticsModal", showReportAndStatisticsModal);
provide("openReportAndStatisticsModal", openReportAndStatisticsModal);
provide("closeReportAndStatisticsModal", closeReportAndStatisticsModal);

// Report note modal
const openReportNoteModal = () => {
  showReportNoteModal.value = true;
};

const closeReportNoteModal = () => {
  showReportNoteModal.value = false;
};

provide("reportNote", reportNote);
provide("reportNoteModal", showReportNoteModal);
provide("openReportNoteModal", openReportNoteModal);
provide("closeReportNoteModal", closeReportNoteModal);

// File upload successful modal
const openFileUploadSuccessfulModal = () => {
  showFileUploadSuccessfulModal.value = true;
};

const closeFileUploadSuccessfulModal = () => {
  showFileUploadSuccessfulModal.value = false;
};

provide("fileUploadSuccessfulModal", showReportAndStatisticsModal);
provide("openFileUploadSuccessfulModal", openFileUploadSuccessfulModal);
provide("closeFileUploadSuccessfulModal", closeFileUploadSuccessfulModal);

// Current Patient and device
provide("currentPatientAndDevice", currentPatientAndDevice);

// Test center modal
const openSelectTestTypeModal = () => {
  showSelectTestTypeModal.value = true;
};

const closeSelectTestTypeModal = () => {
  showSelectTestTypeModal.value = false;
};

// search patient
const openSearchPatientModal = () => {
  showSearchPatientModal.value = true;
};

const closeSearchPatientModal = () => {
  showSearchPatientModal.value = false;
};

const openTestModal = () => {
  showTestModal.value = true;
};

const closeTestModal = () => {
  showTestModal.value = false;
};

provide("selectedPatient", selectedPatient);
provide("showSelectTestTypeModal", showSelectTestTypeModal);
provide("openSelectTestTypeModal", openSelectTestTypeModal);
provide("closeSelectTestTypeModal", closeSelectTestTypeModal);
provide("searchPatientModal", showSearchPatientModal);
provide("openSearchPatientModal", openSearchPatientModal);
provide("closeSearchPatientModal", closeSearchPatientModal);
provide("testModal", showTestModal);
provide("openModal", openTestModal);
provide("closeModal", closeTestModal);

// Add new patient modal
const openAddNewPatientModal = () => {
  showAddNewPatientModal.value = true;
};

const closeAddNewPatientModal = () => {
  showAddNewPatientModal.value = false;
};

provide("addNewPatientModal", showAddNewPatientModal);
provide("openAddNewPatientModal", openAddNewPatientModal);
provide("closeAddNewPatientModal", closeAddNewPatientModal);

// Test steps modal
const openTestStepsModal = () => {
  showTestStepsModal.value = true;
};

const closeTestStepsModal = () => {
  showTestStepsModal.value = false;
};

provide("testStepsModal", showTestStepsModal);
provide("openTestStepsModal", openTestStepsModal);
provide("closeTestStepsModal", closeTestStepsModal);

// Test successful modal
const openTestSuccessfulModal = () => {
  showTestSuccessfulModal.value = true;
};

const closeTestSuccessfulModal = () => {
  showTestSuccessfulModal.value = false;
};

provide("testSuccessfulModal", showTestSuccessfulModal);
provide("openTestSuccessfulModal", openTestSuccessfulModal);
provide("closeTestSuccessfulModal", closeTestSuccessfulModal);

// Blog post
// view blog post
const openViewBlogModal = (post) => {
  selectedPost.value = post;
  showBlogPostModal.value = true;
};

const closeViewBlogModal = () => {
  showBlogPostModal.value = false;
};

// edit blog post
const openEditBlogPostModal = (post) => {
  selectedPost.value = post;
  showEditBlogModal.value = true;
};

const closeEditBlogModal = () => {
  showEditBlogModal.value = false;
};

// open delete blog post
const openDeleteBlogPostModal = (post) => {
  selectedPost.value = post;
  showDeleteBlogModal.value = true;
};

const closeDeleteBlogModal = () => {
  showDeleteBlogModal.value = false;
};

provide("openBlogPostModal", openViewBlogModal);
provide("closeViewBlogModal", closeViewBlogModal);
provide("openEditBlogModal", openEditBlogPostModal);
provide("closeEditBlogModal", closeEditBlogModal);
provide("openDeleteBlogModal", openDeleteBlogPostModal);
provide("closeDeleteBlogModal", closeDeleteBlogModal);
</script>
