<template>
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.043 0C4.50333 0 0.00732422 4.48 0.00732422 10C0.00732422 15.52 4.50333 20 10.043 20C15.5828 20 20.0788 15.52 20.0788 10C20.0788 4.48 15.5828 0 10.043 0ZM15.0609 11H11.0466V15H9.03947V11H5.02518V9H9.03947V5H11.0466V9H15.0609V11Z"
      fill="#011D7E"
    />
  </svg>
</template>
