<template>
  <div
    class="mx-auto px-2 lg:px-16 w-full min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      class="bg-white p-4 md:p-10 rounded-lg shadow-xl w-full max-w-full h-[40rem] relative"
    >
      <div
        class="w-full md:w-[80%] flex flex-row items-center justify-between mb-5 min-h-[10%]"
      >
        <div class="flex items-center flex-wrap gap-4 md:gap-x-8">
          <p class="text-ResolutionBlue font-bold text-xl lg:text-2xl">
            Account Linking
          </p>
          <div
            class="flex flex-row items-center rounded-sm bg-BlueChalk px-1.5 py-1"
          >
            <span
              @click="toggleTabs(1)"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-5 py-1.5': openTab !== 1,
                'font-bold rounded-sm px-5 py-1.5 text-white bg-ResolutionBlue':
                  openTab === 1,
              }"
            >
              Link Account
            </span>
            <span
              @click="toggleTabs(2)"
              class="cursor-pointer text-sm flex items-center space-x-2"
              :class="{
                'text-PaleSky px-5 py-1.5': openTab !== 2,
                'font-bold rounded-sm px-5 py-1.5 text-white bg-ResolutionBlue':
                  openTab === 2,
              }"
            >
              <span>Pending Request{{ totalPendingRequest > 1 ? "s" : "" }}</span>
              <span
                :class="{
                  'text-black/70 bg-black/10': openTab !== 2,
                  'bg-white text-ResolutionBlue': openTab === 2,
                }"
                class="text-xs px-[5px] py-[1px] rounded-full"
                >{{ totalPendingRequest }}</span
              >
            </span>
          </div>
        </div>
      </div>

      <div class="w-full h-[90%]">
        <div class="h-full py-6 overflow-y-scroll no-scrollbar">
          <div :class="{ hidden: openTab !== 1, block: openTab === 1 }">
            <LinkAccount />
          </div>
          <div :class="{ hidden: openTab !== 2, block: openTab === 2 }">
            <AccountRequests />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import LinkAccount from "@/components/main/patient/accountLinking/LinkAccount.vue";
import AccountRequests from "@/components/main/patient/accountLinking/AccountRequests.vue";
const openTab = ref(1);

const { "accountConnection/fetchPatientRequests": fetchPatientRequests } =
  mapActions();
const { "accountConnection/getPatientRequests": getPatientRequests } =
  mapGetters();

const totalacceptedRequest = computed(
  () =>
    (getPatientRequests?.value.total_accepted_connections_sent || 0) +
    (getPatientRequests?.value.total_accepted_connections_received || 0)
);

const totalPendingRequest = computed(
  () =>
    (getPatientRequests?.value.total_pending_connections_sent || 0) +
    (getPatientRequests?.value.total_pending_connections_received || 0)
);

const toggleTabs = (tabNumber) => {
  openTab.value = tabNumber;
};

onMounted(async () => {
  await fetchPatientRequests();
});
</script>
