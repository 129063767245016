<template>
  <div
    class="absolute left-0 top-full z-10 bg-white w-full max-w-[450px] h-[140px] py-5 px-[30px] rounded-lg border border-BluishGrey flex flex-col gap-[10px] overflow-y-scroll no-scrollbar"
  >
    <div
      v-if="procedures.length > 0"
      v-for="(procedure, index) in procedures"
      :key="index"
      class="flex items-center gap-4 cursor-pointer"
      @click="selectProcedure(procedure)"
    >
      <p class="text-DarkJungleGreen text-sm font-medium">
        {{ procedure }}
      </p>
    </div>
    <div v-else class="h-full flex justify-center items-center gap-4">
      <p class="text-DarkJungleGreen text-sm font-semibold">
        No procedure found
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProceduresDropdown",
  props: {
    procedures: {
      type: Array,
      default: [],
    },
  },
  emits: ["setProcedure"],
  setup(_, { emit }) {
    const selectProcedure = (value) => {
      emit("setProcedure", value);
    };

    return {
      selectProcedure,
    };
  },
};
</script>
