<template>
  <div
    class="fixed top-0 left-0 z-[75] h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar"
  >
    <div
      class="w-full max-w-[1080px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto"
    >
      <div>
        <div class="flex justify-between items-center mb-6">
          <div class="flex gap-2.5 md:gap-5 items-center">
            <span class="text-OlsoGrey text-base font-bold">Lab Test</span>
            <div class="flex items-center space-x-3">
              <DirectionalRightIcon width="8" height="12" />
              <span class="text-ResolutionBlue text-base font-bold"
                >Add New Patient</span
              >
            </div>
          </div>
          <div
            class="self-start cursor-pointer flex justify-center items-center"
            @click="closeAddNewPatientModal"
          >
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>

        <form @submit.prevent="">
          <div
            class="grid grid-cols-1 md:grid-cols-3 gap-1 md:gap-x-8 md:gap-y-16 mb-2 md:mb-4"
          >
            <div class="w-full">
              <label for="firstName" class="w-full text-sm">First Name</label>
              <input
                type="text"
                class="w-full outline-none border-BlueChalk border-0 border-b-[1px]"
                id="firstName"
                required
                v-model="first_name"
              />
            </div>
            <div class="w-full">
              <label for="lastName" class="w-full text-sm">Last Name</label>
              <input
                type="text"
                class="w-full outline-none border-BlueChalk border-0 border-b-[1px]"
                id="lastName"
                required
                v-model="last_name"
              />
            </div>
            <div class="w-full">
              <label for="username" class="w-full text-sm">Username</label>
              <input
                type="text"
                class="w-full outline-none border-BlueChalk border-0 border-b-[1px]"
                id="username"
                required
                v-model="username"
              />
            </div>
            <div class="w-full">
              <label for="phone" class="w-full text-sm">Phone Number</label>
              <input
                type="tel"
                class="w-full outline-none border-BlueChalk border-0 border-b-[1px]"
                id="phone"
                required
                v-model="phone"
              />
            </div>
            <div class="w-full">
              <label for="dateOfBirth" class="w-full text-sm"
                >Date of Birth</label
              >
              <input
                type="date"
                class="w-full outline-none border-BlueChalk border-0 border-b-[1px]"
                id="date_of_birth"
                required
                v-model="date_of_birth"
              />
            </div>
            <div>
              <div class="w-full">
                <label for="email" class="w-full text-sm">Email</label>
                <input
                  type="email"
                  class="w-full outline-none border-BlueChalk border-0 border-b-[1px]"
                  id="email"
                  required
                  v-model="email"
                />
              </div>
              <div>
                <div class="text-ArtyClickRed" v-if="email_exist === true">
                  Email already exist
                </div>
                <div
                  class="text-ArtyClickRed"
                  v-else-if="invalid_email_error === 'Email is not valid.'"
                >
                  Email is not valid
                </div>
              </div>
              <div
                class="text-ArtyClickRed"
                v-if="
                  signupError ===
                  'The email address is not valid. It must have exactly one @-sign.'
                "
              >
                Invalid email
              </div>
            </div>
            <!-- <div>
              <div class="w-full">
                <label for="password" class="w-full text-sm">Password</label>
                <input
                  type="password"
                  class="w-full outline-none border-BlueChalk border-0 border-b-[1px]"
                  id="password"
                  required
                  v-model="password"
                />
              </div>
              <div
                class="text-ArtyClickRed"
                v-if="signupError === 'password cannot be empty.'"
              >
                Password is required.
              </div>
              <div
                class="text-ArtyClickRed"
                v-if="signupError === 'Password is too short.'"
              >
                Password is too short
              </div>
            </div>
            <div>
              <div class="w-full">
                <label for="dateOfBirth" class="w-full text-sm"
                  >Confirm password</label
                >
                <input
                  type="password"
                  class="w-full outline-none border-BlueChalk border-0 border-b-[1px]"
                  id="password_2"
                  required
                  v-model="password_2"
                />
              </div>
              <div
                class="text-ArtyClickRed"
                v-if="signupError === 'password_2 cannot be empty.'"
              >
                Confirmation password is required
              </div>
              <div
                class="text-ArtyClickRed"
                v-if="signupError === 'Confirmation password doesn\'t match.'"
              >
                Confirmation password doesn't match
              </div>
            </div> -->
          </div>

          <div class="mt-9 flex justify-center items-center gap-[10px]">
            <button
              @click="closeAddNewPatientModal"
              class="w-[147px] p-1.5 md:p-3 border border-ResolutionBlue text-ResolutionBlue rounded-[44px] text-center text-base font-semibold"
            >
              Cancel
            </button>
            <button
              @click="doesEmailExist"
              class="w-[147px] p-1.5 md:p-3 bg-Geyser border rounded-[44px] text-MistBlue text-center text-base font-semibold"
            >
              <span v-if="isLoading">
                <LoadingSpinner />
              </span>
              <span v-else>Register</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject } from "vue";
import DirectionalRightIcon from "@/components/icons/DirectionalRightIcon.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { mapActions } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";

const closeAddNewPatientModal = inject("closeAddNewPatientModal");

const first_name = ref("");
const last_name = ref("");
const username = ref("");
const email = ref("");
const date_of_birth = ref("");
const phone = ref("");
const isLoading = ref(false);
const signupError = ref(null);
const email_exist = ref(null);
const invalid_email_error = ref(null);

const { "auth/signupPatient": signupPatient } = mapActions();
const { "auth/isUserExist": isUserExist } = mapActions();

const doesEmailExist = async () => {
  const userEmail = email.value;
  try {
    isLoading.value = true;
    const verifyUserEmail = await isUserExist(userEmail);
    console.log("verifyUserEmail", verifyUserEmail);
    if (verifyUserEmail.email_exists === true) {
      email_exist.value = verifyUserEmail.email_exists;
    } else if (verifyUserEmail.email_exists === false) {
      signUpUser();
    }
  } catch (error) {
    console.error("11", error);
    // console.error("11", error.response.data.message);
    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.message
    )
      invalid_email_error.value = error.response.data.message;
    console.log("invalid_email_error 9m", invalid_email_error);
  } finally {
    isLoading.value = false;
  }
};

const signUpUser = async () => {
  const signupCredentials = {
    email: email.value,
    first_name: first_name.value,
    last_name: last_name.value,
    user_name: username.value,
    phone: phone.value,
    date_of_birth: date_of_birth.value,
  };
  try {
    isLoading.value = true;
    const userData = await signupPatient(signupCredentials);
    console.log("userData in 1 com", userData);
    console.log("signupCredentials", signupCredentials);
    if (userData.registration_success === true) {
      console.log("admin registerd user successfully", userData);
      toast.success("Patient account created succesfully", {
        autoClose: 3000,
      });
      // //  Show the popup
      // showPopup.value = true;
      // setTimeout(() => {
      //   showPopup.value = false;
      // }, 4000);
      // Clear the input fields
      email.value = "";
      // password.value = "";
      // password_2.value = "";
      first_name.value = "";
      last_name.value = "";
      phone.value = "";
      date_of_birth.value = "";
    }
  } catch (error) {
    // toast.error("error while signing up", {
    //   autoClose: 3000,
    // });
    if (error.response && error.response.data && error.response.data.message) {
      signupError.value = error.response.data.message;
      console.log("admin sing up patient signupError", signupError.value);
    }
    console.error("admin sing up patient error", error);
  } finally {
    isLoading.value = false;
  }
};
</script>

<script>
export default {
  name: "AddNewPatientModal",
};
</script>
