<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      class="bg-white xl:mx-10 rounded-lg shadow-xl w-full p-4 md:p-10 h-[40rem] relative"
    >
      <div
        class="w-full md:w-[80%] flex flex-row items-center justify-between mb-5 h-[10%]"
      >
        <div
          class="flex items-center flex-wrap gap-y-3 gap-x-10 md:gap-x-16 py-4 md:pt-0"
        >
          <p class="text-ResolutionBlue font-bold text-xl lg:text-2xl">
            Account Information
          </p>

          <div
            class="flex flex-row items-center rounded-sm bg-WhiteLilac p-1.5"
          >
            <span
              @click="toggleTabs(1)"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-6 md:px-10 py-1.5': openTab !== 1,
                'font-bold rounded-sm px-6 md:px-10 py-1.5 text-white bg-ResolutionBlue':
                  openTab === 1,
              }"
            >
              Personal</span
            >
            <span
              @click="toggleTabs(2)"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-6 md:px-10 py-1.5': openTab !== 2,
                'font-bold rounded-sm px-6 md:px-10 py-1.5 text-white bg-ResolutionBlue':
                  openTab === 2,
              }"
            >
              Medical</span
            >
          </div>
        </div>
      </div>

      <div class="h-[90%]">
        <div class="overflow-x-auto h-full py-6">
          <div class="w-[80%] flex flex-col items-start md:flex-row md:gap-x-8">
            <ProfilePicture />

            <PersonalInformation v-show="openTab === 1" />
            <MedicalInformation v-show="openTab === 2" />
          </div>

          <div class="flex justify-center mt-8">
            <p
              class="text-center text-xs md:text-sm font-light text-BluishGrey md:max-w-sm"
            >
              If you have any issues with your information, please send a
              message to hello@turbomedics.com
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import MedicalInformation from "@/components/main/patient/accountInformation/MedicalInformation.vue";
import PersonalInformation from "@/components/main/patient/accountInformation/PersonalInformation.vue";
import ProfilePicture from "@/components/main/patient/accountInformation/ProfilePicture.vue";

const openTab = ref(1);

const toggleTabs = (tabNumber) => {
  openTab.value = tabNumber;
};
</script>
