<template>
  <div class="text-center mt-24">
    <div class="mb-5">
      <img
        src="../../../assets/icons/reset-checkmark.svg"
        alt="Successful check icon"
        class="mx-auto"
      />
    </div>

    <div class="mb-8">
      <h1 class="text-black text-2xl font-bold mb-2">
        Password Reset Completed
      </h1>
      <p class="text-OlsoGrey text-base max-w-[305px] mx-auto">
        Password reset complete! You can now log in with your new password.
      </p>
    </div>

    <router-link to="/auth/sign-in">
      <p class="text-ResolutionBlue text-sm font-bold mb-8">Back to login</p>
    </router-link>

    <div class="bg-ResolutionBlue/10 rounded-2xl py-3 px-7">
      <p class="text-ResolutionBlue text-xs max-w-[217px] mx-auto">
        If you did not initiate the request, kindly contact us at
        <span class="font-semibold">support@turbomedics.com</span>
      </p>
    </div>
  </div>
</template>
