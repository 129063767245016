import { computed, watch } from "vue";

export default function useCheckPassword(password, passwordError) {
  const isDisabled = computed(() => {
    return !(
      !!password.value &&
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/.test(password.value)
    );
  });

  watch(password, () => {
    const numberCheck = /(?=.*[0-9])/.test(password.value);
    const smallLetterCheck = /(?=.*[a-z])/.test(password.value);
    const bigLetterCheck = /(?=.*[A-Z])/.test(password.value);

    if (password.value.length === 0) {
      passwordError.value = "";
    } else if (!numberCheck) {
      passwordError.value = "Password should contain at least one number";
    } else if (numberCheck && !smallLetterCheck) {
      passwordError.value = "Password should contain at least one small letter";
    } else if (numberCheck && smallLetterCheck && !bigLetterCheck) {
      passwordError.value =
        "Password should contain at least one uppercase letter";
    } else if (password.value.length < 8) {
      passwordError.value = "Password should not be less than 8 characters";
    } else {
      passwordError.value = "";
    }
  });

  return isDisabled;
}
