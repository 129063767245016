<template>
  <div
    class="mx-auto px-2 lg:px-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10">
      <PatientsList />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PatientsList from "@/components/main/admin/PatientsList.vue";

export default defineComponent({
  name: "AdminDashboardView",
  components: {
    PatientsList,
  },
});
</script>
