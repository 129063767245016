<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10">
      <div class="max-w-5xl w-full ">
        <!-- <div class="border rounded-3xl p-5 h-[23rem]">
            <div class="h-[10%]">
              <div class="flex items-center justify-between">
                <p class="font-semibold">Blood Glucose</p>
                <div class="flex items-center space-x-4">
                  <div class="flex items-center space-x-2">
                    <div class="bg-BilobaFlower w-2 h-2 rounded-full"></div>
                    <span class="text-Dolphin text-[10px] lg:text-xs"
                      >Before meal</span
                    >
                  </div>
                  <div class="flex items-center space-x-2">
                    <div class="bg-TealishGreen w-2 h-2 rounded-full"></div>
                    <span class="text-Dolphin text-[10px] lg:text-xs"
                      >After meal</span
                    >
                  </div>
                </div>
                <button
                  class="border-[0.1px] border-OlsoGrey rounded-md px-4 py-1.5 text-xs"
                >
                  Today
                </button>
              </div>
            </div>
            <div class="h-[90%]">
              <div class="h-full flex justify-center items-center">
                <div class="flex flex-col space-y-6 items-center">
                  <AddChartIcon />
                  <p class="text-sm text-DavyGrey">You have no chart yetww</p>
                  <button
                    class="w-fit rounded-3xl bg-ResolutionBlue text-white px-10 py-3 text-sm font-semibold"
                  >
                    Capture your vitals
                  </button>
                </div>
              </div>
            </div>
          </div> -->
        <div class="w-full border rounded-3xl p-5 h-[23rem]">
          <div class="h-[10%] flex items-center justify-between">
            <p class="font-semibold">History</p>
            <div class="flex flex-row items-center space-x-6">
              <div
                class="w-fit rounded-3xl px-4 py-2 bg-OrangePeel text-white text-xs"
              >
                Avg:
                <span class="font-semibold">{{ average_high }}mg/dl</span>
              </div>
              <div class="flex space-x-4">
                <div class="flex items-center space-x-2">
                  <div class="bg-DodgerBlue w-1 h-1 rounded-full"></div>
                  <span class="text-DodgerBlue text-[10px] lg:text-xs"
                    >Low</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <div class="bg-DarkMint w-1 h-1 rounded-full"></div>
                  <span class="text-DarkMint text-[10px] lg:text-xs"
                    >Normal</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <div class="bg-OrangePeel w-1 h-1 rounded-full"></div>
                  <span class="text-OrangePeel text-[10px] lg:text-xs"
                    >Average High</span
                  >
                </div>
                <div class="flex items-center space-x-2">
                  <div class="bg-ArtyClickRed w-1 h-1 rounded-full"></div>
                  <span class="text-ArtyClickRed text-[10px] lg:text-xs"
                    >High</span
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="h-[90%]">
            <div class="overflow-x-auto h-full py-6">
              <table class="w-full">
                <thead>
                  <tr>
                    <th
                      class="min-w-[100px] py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Glucose Level
                    </th>

                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Meal
                    </th>
                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Date
                    </th>
                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200 text-left text-sm font-bold text-[#25282B]"
                    >
                      Time
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(
                      glucose_record, index
                    ) in getUserBloodGlucoseRecords?.results"
                    :key="index"
                    class="font-light"
                  >
                    <td
                      class="min-w-[100px] py-5 border-t border-gray-200 text-sm"
                      :class="[
                        glucose_record?.glucose_level < hypoglycaemic
                          ? 'text-DodgerBlue'
                          : glucose_record?.glucose_level >= hypoglycaemic &&
                            glucose_record?.glucose_level <= normal
                          ? 'text-DarkMint'
                          : glucose_record?.glucose_level > normal &&
                            glucose_record?.glucose_level <= average_high
                          ? 'text-OrangePeel'
                          : glucose_record?.glucose_level >= high
                          ? 'text-ArtyClickRed'
                          : '',
                      ]"
                    >
                      {{ glucose_record?.glucose_level }} mg/dl
                    </td>
                    <td
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      {{
                        glucose_record?.before_meal === true
                          ? "Before meal"
                          : "After meal"
                      }}
                    </td>
                    <td
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      {{ dateFormatter(glucose_record?.check_time) }}
                    </td>
                    <td
                      class="min-w-[130px] pl-5 py-5 border-t border-gray-200 text-sm"
                    >
                      {{ timeFormatter(glucose_record?.check_time) }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- <div
                  v-if="totalPages > 1"
                  class="flex justify-center items-center space-x-3 border-t pt-4"
                >
                  <button
                    @click="goToPage(page - 1)"
                    :disabled="page === 1"
                    class="text-white bg-ResolutionBlue px-3 py-1.5 rounded-md"
                  >
                    Previous
                  </button>
                  <span>Page {{ page }} of {{ totalPages }}</span>
                  <button
                    @click="goToPage(page + 1)"
                    :disabled="page === totalPages"
                    class="text-white bg-ResolutionBlue px-3 py-1.5 rounded-md"
                  >
                    Next
                  </button>
                </div> -->
            </div>
            <!-- <div v-else class="h-full flex justify-center items-center">
                <div class="flex flex-col space-y-6 items-center">
                  <img
                    src="../../../assets/icons/blood_glucose_history_icon.svg"
                    alt=""
                    class="w-12"
                  />
                  <p class="text-sm text-DavyGrey">You have no history yet</p>
                  <button
                    class="w-fit rounded-3xl bg-ResolutionBlue text-white px-10 py-3 text-sm font-semibold"
                  >
                    Capture your vitals
                  </button>
                </div>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, ref, onMounted, computed, inject } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import AddChartIcon from "@/components/icons/AddChartIcon.vue";
import { dateFormatter } from "@/utils/dateFormatter";
import { timeFormatter } from "@/utils/dateFormatter";

const page = ref(1);
const perPage = ref(3);
const hypoglycaemic = ref(70);
const normal = ref(100);
const average_high = ref(125);
const high = ref(126);

const { "user/fetchBloodGlucoseRecords": fetchBloodGlucoseRecords } =
  mapActions();

const { "user/getUserBloodGlucoseRecords": getUserBloodGlucoseRecords } =
  mapGetters();

const postData = computed(() => {
  return getUserBloodGlucoseRecords.value;
});

const totalPages = computed(() => {
  return Math.ceil(postData.value?.glucose_records?.length / perPage.value);
});

const paginatedRecords = computed(() => {
  if (postData.value && postData.value?.glucose_records) {
    const formattedRecords = postData.value.glucose_records.map((record) => {
      const dateComponents = record.check_time.split(" ");
      const datePart = dateComponents[0].split("-").reverse().join("-");
      const timePart = dateComponents[1];
      const formattedTime = `${datePart} ${timePart}`;
      return { ...record, formatted_check_time: formattedTime };
    });
    const sortedRecords = formattedRecords.sort((a, b) => {
      const time_a = new Date(a.formatted_check_time).getTime();
      const time_b = new Date(b.formatted_check_time).getTime();
      return time_b - time_a;
    });
    const start = (page.value - 1) * perPage.value;
    const end = start + this.perPage;
    return sortedRecords.slice(start, end);
  }
  return [];
});

const goToPage = (page) => {
  if (page >= 1 && page <= totalPages.value) {
    page.value = page;
  }
};

onMounted(() => {
  fetchBloodGlucoseRecords();
  console.log("i don", getUserBloodGlucoseRecords.value);
});
</script>
