<template>
  <svg viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.8291 10.5L6.3291 6L1.8291 1.5"
      stroke="#808D9E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
