<template>
  <div class="flex flex-col items-start md:flex-row md:space-x-16 px-6">
    <div class="w-full md:w-[15%]">
      <div class="flex flex-col items-center form-group">
        <div class="w-32 h-32 rounded-full border">
          <template v-if="preview">
            <img :src="preview" class="w-32 h-32 rounded-full object-cover" />
          </template>
          <template v-else>
            <img
              src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
              class="w-32 h-32 rounded-full object-cover"
            />
          </template>
        </div>
      </div>
    </div>
    <div class="w-full md:w-[50%]">
      <div
        class="space-y-2.5"
        :class="{
          'border-b border-b-BlueChalk mb-4': openBasiInfo !== true,
          'mb-12': openBasiInfo === true,
          'transition-transform duration-700': true,
        }"
      >
        <div
          class="flex items-center justify-between space-x-4 cursor-pointer"
          @click="openBasiInfo = !openBasiInfo"
        >
          <h4 class="text-MistBlue font-bold text-xs md:text-sm">Basic</h4>
          <div>
            <span
              ><img
                src="../../../../assets/icons/arrow-up-iocn.svg"
                :class="{
                  'rotate-180': openBasiInfo !== true,
                  'transition-transform': true,
                  'duration-700': true,
                }"
                alt=""
            /></span>
          </div>
        </div>
        <div
          :class="[
            {
              'max-h-0': true,
              'overflow-hidden': true,
              'transition-max-height': true,
              'duration-1000': true,
              'max-h-[500px]': openBasiInfo === true,
            },
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Title</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.title ? patient?.person?.title : "--" }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                First name
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.first_name }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Last Name</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.last_name }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">User Name</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.username ? patient?.person?.username : "--"
                }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Date of Birth
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.date_of_birth }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Age</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.age }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Gender</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.gender ? patient?.person?.gender : "--" }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Marital Status
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.marital_status
                    ? patient?.person?.marital_status
                    : "--"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="space-y-2.5"
        :class="{
          'border-b border-b-BlueChalk mb-4': openResidentialAddress !== true,
          'mb-12': openResidentialAddress === true,
          'transition-transform duration-700': true,
        }"
      >
        <div
          class="flex items-center justify-between space-x-4 cursor-pointer"
          @click="openResidentialAddress = !openResidentialAddress"
        >
          <h4 class="text-MistBlue font-bold text-xs md:text-sm">
            Residential Address 1
          </h4>
          <div>
            <span
              ><img
                src="../../../../assets/icons/arrow-up-iocn.svg"
                :class="{
                  'rotate-180': openResidentialAddress !== true,
                  'transition-transform': true,
                  'duration-700': true,
                }"
                alt=""
            /></span>
          </div>
        </div>
        <div
          :class="[
            {
              'max-h-0': true,
              'overflow-hidden': true,
              'transition-max-height': true,
              'duration-1000': true,
              'max-h-[500px]': openResidentialAddress === true,
            },
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Address</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.address ? patient?.person?.address : "--" }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Street</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.street ? patient?.person?.street : "--" }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">State</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.state ? patient?.person?.state : "--" }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">City</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.city ? patient?.person?.city : "--" }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Zip Code</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.zip_code ? patient?.person?.zip_code : "--"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="space-y-2.5"
        :class="{
          'border-b border-b-BlueChalk mb-4': openContact !== true,
          'mb-12': openContact === true,
          'transition-transform duration-700': true,
        }"
      >
        <div
          class="flex items-center justify-between space-x-4 cursor-pointer"
          @click="openContact = !openContact"
        >
          <h4 class="text-MistBlue font-bold text-xs md:text-sm">Contact</h4>
          <div>
            <span
              ><img
                src="../../../../assets/icons/arrow-up-iocn.svg"
                :class="{
                  'rotate-180': openContact !== true,
                  'transition-transform': true,
                  'duration-700': true,
                }"
                alt=""
            /></span>
          </div>
        </div>
        <div
          :class="[
            {
              'max-h-0': true,
              'overflow-hidden': true,
              'transition-max-height': true,
              'duration-1000': true,
              'max-h-[500px]': openContact === true,
            },
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Phone Number (Primary)
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.phone ? patient?.person?.phone : "--" }}
              </p>
            </div>

            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Email</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{ patient?.person?.email }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="space-y-2.5"
        :class="{
          'border-b border-b-BlueChalk mb-4': openSpokenLanguage !== true,
          'mb-12': openSpokenLanguage === true,
          'transition-transform duration-700': true,
        }"
      >
        <div
          class="flex items-center justify-between space-x-4 cursor-pointer"
          @click="openSpokenLanguage = !openSpokenLanguage"
        >
          <h4 class="text-MistBlue font-bold text-xs md:text-sm">
            Spoken Languages
          </h4>
          <div>
            <span
              ><img
                src="../../../../assets/icons/arrow-up-iocn.svg"
                :class="{
                  'rotate-180': openSpokenLanguage !== true,
                  'transition-transform': true,
                  'duration-700': true,
                }"
                alt=""
            /></span>
          </div>
        </div>
        <div
          :class="[
            {
              'max-h-0': true,
              'overflow-hidden': true,
              'transition-max-height': true,
              'duration-1000': true,
              'max-h-[500px]': openSpokenLanguage === true,
            },
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Language</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.language ? patient?.person?.language : "--"
                }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Language Proficiency
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.language_proficiency
                    ? patient?.person?.language_proficiency
                    : "--"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="space-y-2.5"
        :class="{
          'border-b border-b-BlueChalk mb-4': openEmergencyContact !== true,
          'mb-12': openEmergencyContact === true,
          'transition-transform duration-700': true,
        }"
      >
        <div
          class="flex items-center justify-between space-x-4 cursor-pointer"
          @click="openEmergencyContact = !openEmergencyContact"
        >
          <h4 class="text-MistBlue font-bold text-xs md:text-sm">
            Emergency Contact (1)
          </h4>
          <div>
            <span
              ><img
                src="../../../../assets/icons/arrow-up-iocn.svg"
                :class="{
                  'rotate-180': openEmergencyContact !== true,
                  'transition-transform': true,
                  'duration-700': true,
                }"
                alt=""
            /></span>
          </div>
        </div>
        <div
          :class="[
            {
              'max-h-0': true,
              'overflow-hidden': true,
              'transition-max-height': true,
              'duration-1000': true,
              'max-h-[500px]': openEmergencyContact === true,
            },
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Name</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.emergency_contact_name
                    ? patient?.person?.emergency_contact_name
                    : "--"
                }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Relationship
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.emergency_contact_relationship
                    ? patient?.person?.emergency_contact_relationship
                    : "--"
                }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Phone Number (Primary)
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.emergency_contact_phone
                    ? patient?.person?.emergency_contact_phone
                    : "--"
                }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Phone Number (Secondary)
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.second_emergency_contact_phone
                    ? patient?.person?.second_emergency_contact_phone
                    : "--"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="space-y-2.5"
        :class="{
          'border-b border-b-BlueChalk mb-4': openEmergencyContact_2 !== true,
          'mb-12': openEmergencyContact_2 === true,
          'transition-transform duration-700': true,
        }"
      >
        <div
          class="flex items-center justify-between space-x-4 cursor-pointer"
          @click="openEmergencyContact_2 = !openEmergencyContact_2"
        >
          <h4 class="text-MistBlue font-bold text-xs md:text-sm">
            Emergency Contact (2)
          </h4>
          <div>
            <span
              ><img
                src="../../../../assets/icons/arrow-up-iocn.svg"
                :class="{
                  'rotate-180': openEmergencyContact_2 !== true,
                  'transition-transform': true,
                  'duration-700': true,
                }"
                alt=""
            /></span>
          </div>
        </div>
        <div
          :class="[
            {
              'max-h-0': true,
              'overflow-hidden': true,
              'transition-max-height': true,
              'duration-1000': true,
              'max-h-[500px]': openEmergencyContact_2 === true,
            },
          ]"
        >
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">Name</p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.second_emergency_contact_name
                    ? patient?.person?.second_emergency_contact_name
                    : "--"
                }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Relationship
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.second_emergency_contact_relationship
                    ? patient?.person?.second_emergency_contact_relationship
                    : "--"
                }}
              </p>
            </div>
            <div
              class="flex flex-col w-full bg-WhiteLilac pt-4 pb-3 border-b-2 border-BlueChalk"
            >
              <p class="px-2 text-[10px] md:text-xs text-OlsoGrey">
                Phone Number (Secondary)
              </p>
              <p class="px-2 text-xs md:text-sm text-DavyGrey font-bold">
                {{
                  patient?.person?.second_emergency_contact_phone
                    ? patient?.person?.second_emergency_contact_phone
                    : "--"
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps(["patient"]);
console.log("patient prop in per", props.patient);

const openBasiInfo = ref(true);
const openResidentialAddress = ref(true);
const openContact = ref(true);
const openSpokenLanguage = ref(true);
const openEmergencyContact = ref(true);
const openEmergencyContact_2 = ref(true);
const openPreferredHospital = ref(true);
</script>

<script>
export default {
  name: "PatientPersonalInfo",
};
</script>
