<template>
  <svg viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="icon / icofont / directional / simple / left" opacity="0.7">
      <path
        id="&#238;&#170;&#181;"
        d="M9.92109 2.93311L5.12109 7.73311L9.92109 12.5331L11.2971 11.1731L7.85709 7.73311L11.2971 4.29311L9.92109 2.93311Z"
        fill="#808D9E"
      />
    </g>
  </svg>
</template>
