<template>
  <AllModalsWrapper>
    <section class="w-full flex flex-row">
      <Sidebar />

      <div class="h-full w-full lg:w-[calc(100%-100px)] bg-WhiteLilac">
        <Header />

        <router-view></router-view>
      </div>
    </section>
  </AllModalsWrapper>
</template>

<script setup>
import { ref, provide, onMounted } from "vue";
import { useRoute } from "vue-router";
import { mapActions } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";
import AllModalsWrapper from "@/components/main/AllModalsWrapper.vue";
import Header from "@/components/main/header/Header.vue";
import Sidebar from "@/components/main/sidebar/Sidebar.vue";

const isSidebarOpen = ref(false);

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
};

provide("isSidebarOpen", isSidebarOpen);
provide("toggleSidebar", toggleSidebar);

const route = useRoute();

const appointmentTypes = ["all", "alert", "announcement", "reminder"];

let routeQuery = route.query.type;

if (!appointmentTypes.includes(routeQuery)) {
  routeQuery = "all";
}

const filter = ref(routeQuery);

const {
  "notifications/fetchNotifications": fetchNotifications,
  "notifications/initializeSocket": initializeSocket,
} = mapActions();

const fetchAllNotifications = async () => {
  try {
    await fetchNotifications({
      type_filter: filter.value,
      time_period: "today",
    });
  } catch (error) {
    toast.error("Error fetching notifications", {
      autoClose: 2000,
    });
  }
};

onMounted(async () => {
  await fetchAllNotifications();
  initializeSocket();
});
</script>
