<template>
  <div class="flex justify-between mb-6">
    <div class="bg-Seashell flex space-x-4 p-2 rounded-lg">
      <button
        class="filter-btn"
        :class="{ active: filter === 'all' }"
        @click="$emit('setNotificationFilter', 'all')"
      >
        All <span class="count">{{ allNotifications.length }}</span>
      </button>
      <button
        class="filter-btn"
        :class="{ active: filter === 'alert' }"
        @click="$emit('setNotificationFilter', 'alert')"
      >
        Alert<span class="count">{{ alertNotifications.length }}</span>
      </button>
      <button
        class="filter-btn"
        :class="{ active: filter === 'announcement' }"
        @click="$emit('setNotificationFilter', 'announcement')"
      >
        Announcement<span class="count">{{
          announcementNotifications.length
        }}</span>
      </button>
      <button
        class="filter-btn"
        :class="{ active: filter === 'reminder' }"
        @click="$emit('setNotificationFilter', 'reminder')"
      >
        Reminders
        <span class="count">{{ reminderNotifications.length }}</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { mapGetters } from "@/hooks/mapStore";

defineProps({
  filter: {
    type: String,
  },
});

defineEmits(["setNotificationFilter"]);

const {
  "notifications/getAllNotifications": allNotifications,
  "notifications/getNotificationsByType": getNotificationsByType,
} = mapGetters();

const alertNotifications = computed(() =>
  getNotificationsByType.value("Alert")
);
const announcementNotifications = computed(() =>
  getNotificationsByType.value("Announcement")
);
const reminderNotifications = computed(() =>
  getNotificationsByType.value("Reminder")
);
</script>

<style scoped>
.filter-btn {
  @apply bg-Seashell flex items-center font-medium py-2 px-4 border-none rounded-lg text-gray-700 focus:outline-none;
}

.filter-btn.active {
  @apply bg-white text-blue-900 font-bold;
}

.count {
  @apply ml-2 py-1 px-2 rounded-full bg-gray-300 text-xs font-semibold;
}
</style>
