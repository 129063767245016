<template>
  <div class="w-full h-screen flex justify-center items-center px-4">
    <div>
      <div>
        <router-link to="/">
          <img
            src="../../../assets/icons/turbomedic-logo__.svg"
            alt="logo"
            class="w-40 md:w-56 mx-auto"
          />
        </router-link>
      </div>

      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResetPasswordWrapper",
};
</script>
