<template>
  <svg
    width="36"
    height="35"
    viewBox="0 0 36 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="perm_device_information" clip-path="url(#clip0_6400_11128)">
      <path
        id="Vector"
        d="M19.4582 10.2085H16.5415V13.1252H19.4582V10.2085ZM19.4582 16.0418H16.5415V24.7918H19.4582V16.0418ZM25.2915 1.47308L10.7082 1.4585C9.104 1.4585 7.7915 2.771 7.7915 4.37516V30.6252C7.7915 32.2293 9.104 33.5418 10.7082 33.5418H25.2915C26.8957 33.5418 28.2082 32.2293 28.2082 30.6252V4.37516C28.2082 2.771 26.8957 1.47308 25.2915 1.47308V1.47308ZM25.2915 27.7085H10.7082V7.29183H25.2915V27.7085Z"
        fill="#011D7E"
      />
    </g>
    <defs>
      <clipPath id="clip0_6400_11128">
        <rect width="35" height="35" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
</template>
