<template>
    <svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="16" height="16" rx="4" fill="#011D7E"/>
  <path
    d="M13.1796 7.76225C12.6926 7.19356 12.4713 6.70073 12.4713 5.86347V5.57879C12.4713 4.48772 12.2202 3.78474 11.6742 3.08176C10.8328 1.99003 9.41617 1.33203 8.0294 1.33203H7.97043C6.61282 1.33203 5.24063 1.95981 4.38458 3.00723C3.8088 3.72431 3.52854 4.45751 3.52854 5.57879V5.86347C3.52854 6.70073 3.32181 7.19356 2.82024 7.76225C2.45119 8.18122 2.33325 8.71971 2.33325 9.3025C2.33325 9.88597 2.52474 10.4385 2.90903 10.8877C3.4106 11.4262 4.11889 11.77 4.84242 11.8297C5.88995 11.9493 6.93748 11.9942 8.00025 11.9942C9.06236 11.9942 10.1099 11.919 11.1581 11.8297C11.8809 11.77 12.5892 11.4262 13.0908 10.8877C13.4744 10.4385 13.6666 9.88597 13.6666 9.3025C13.6666 8.71971 13.5486 8.18122 13.1796 7.76225Z"
    fill="#FAFAFA"
  />
  <path
    opacity="0.4"
    d="M9.33893 12.819C9.00565 12.7478 6.97486 12.7478 6.64159 12.819C6.35668 12.8848 6.04858 13.0379 6.04858 13.3736C6.06515 13.6939 6.25266 13.9765 6.51239 14.1558L6.51172 14.1565C6.84765 14.4183 7.24188 14.5848 7.65466 14.6446C7.87464 14.6748 8.09859 14.6735 8.32651 14.6446C8.73864 14.5848 9.13287 14.4183 9.46879 14.1565L9.46813 14.1558C9.72786 13.9765 9.91537 13.6939 9.93193 13.3736C9.93193 13.0379 9.62383 12.8848 9.33893 12.819Z"
    fill="#FAFAFA"
  />
</svg>

  </template>
  