<template>
  <div
    class="w-full md:w-[50%] p-10 rounded-3xl border-[0.1px] border-RegentGrey space-y-4"
  >
    <h4 class="font-semibold text-xl">Two-Factor Authentication (2FA)</h4>
    <p class="text-GreyChateau text-[10px] md:text-xs">
      Choose how to receive your authentication code
    </p>
    <!-- <AuthenticationApp /> -->
    <AuthenticationViaEmail />
  </div>
</template>

<script setup>
// import AuthenticationApp from "@/views/dashboard/patient/twoFactorAuthentication/authenticationApp/AuthenticationApp.vue";
import AuthenticationViaEmail from "./emailOtp/AuthenticationViaEmail.vue";
</script>

<script>
export default {
  name: "TwoFactorAuthentication",
};
</script>
