<template>
  <td class="min-w-[180px] py-2.5 border-t border-gray-200 text-sm">
    <div class="flex items-center cursor-pointer">
      <div class="flex-shrink-0 w-7 h-7">
        <img
          class="w-full h-full rounded-full object-cover"
          :src="
            currentAccount?.avatar_url ||
            account?.health_centre?.avatar_url ||
            noAvatar
          "
          alt="profile picture"
        />
      </div>

      <div class="ml-3">
        <router-link
          :to="routeToAccountDetails"
          class="font-bold text-DavyGrey whitespace-no-wrap"
        >
          {{ currentAccount?.full_name || account?.health_centre?.name }}
        </router-link>
      </div>
    </div>
  </td>
  <td
    class="min-w-[100px] pl-5 py-2.5 border-t text-DavyGrey border-gray-200 text-sm"
  >
    {{ account?.relationship }}
  </td>
  <td
    class="min-w-[200px] pl-5 py-2.5 border-t text-DavyGrey border-gray-200 text-sm"
  >
    {{ dateFormatter_2(account?.updated_at) }}
  </td>
  <td
    class="min-w-[100px] pl-5 py-2.5 border-t text-DavyGrey border-gray-200 text-sm"
  >
    {{ timeFormatter_2(account?.updated_at) }}
  </td>
  <td class="min-w-[100px] pr-4 py-2.5 border-t border-gray-200 text-right">
    <button
      @click="unlinkPatient"
      class="text-ArtyClickRed border border-ArtyClickRed px-3 py-1 rounded-3xl text-xs font-medium hover:border-ArtyClickRed hover:bg-ArtyClickRed hover:text-white transition-all duration-300 w-16"
    >
      <span v-if="isLoading" class="pl-3">
        <LoadingSpinner />
      </span>
      <span v-else>unlink</span>
    </button>
  </td>
</template>

<script setup>
import { ref, computed } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";
import { dateFormatter_2 } from "@/utils/dateFormatter";
import { timeFormatter_2 } from "@/utils/dateFormatter";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";

const noAvatar =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const props = defineProps(["account"]);

const { "user/getPatientId": patientId } = mapGetters();

const {
  "accountConnection/unlinkPatientAccount": unlinkPatientAccount,
  "accountConnection/fetchPatientRequests": fetchPatientRequests,
} = mapActions();

const getAccount = (account) => {
  if (account?.sender_id === patientId.value) {
    return account?.patient_recipient;
  } else {
    return account?.patient_sender;
  }
};

const currentAccount = getAccount(props.account);

const isLoading = ref(false);

const unlinkPatient = async () => {
  try {
    isLoading.value = true;
    const unlinkPatientId = await unlinkPatientAccount({
      id: props.account.id,
    });
    if (unlinkPatientId) {
      console.log("unlinkPatientId 11", unlinkPatientId);
      toast.success("Patient unlinked successfully", {
        autoClose: 3000,
      });
      fetchPatientRequests();
      console.log("unlinkPatientId unlinkPatientId:", unlinkPatientId);
    }
  } catch (error) {
    console.log("error ee", error);
    if (error) {
      toast.error(error, {
        autoClose: 3000,
      });
    }
    console.error("er accepting request", error);
  } finally {
    isLoading.value = false;
  }
};

const routeToAccountDetails = computed(() => {
  if (currentAccount?.full_name) {
    return `/patient/settings/account-linking/patient/${currentAccount.id}`;
  } else if (props.account?.health_centre?.id) {
    return `/patient/settings/account-linking/center/${props.account.health_centre.id}`;
  }
  return "#";
});
</script>
