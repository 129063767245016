<template>
  <main
    class="mx-auto px-2 lg:px-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10">
      <div
        class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 xl:gap-6 mb-8"
      >
        <PatientProgressReport />

        <PatientVitals />

        <PatientDocuments />
      </div>

      <div>
        <!-- 
          class="grid md:grid-cols-[minmax(auto,_522px)_minmax(auto,_533px)] lg:grid-cols-[minmax(auto,_322px)_minmax(auto,_333px)_minmax(auto,_449px)] gap-5 mb-4"
         -->
        <div class="flex flex-col md:flex-row gap-5 mb-4">
          <div class="w-full md:w-[25%]">
            <RPM />
          </div>

          <div class="w-full md:w-[35%]">
            <ConnectedDevices />
          </div>

          <div class="w-full md:w-[40%]">
            <BookAppointment />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { onMounted } from "vue";
import { mapActions } from "@/hooks/mapStore";
import PatientProgressReport from "@/components/main/patient/dashboard/PatientProgressReport.vue";
import PatientVitals from "@/components/main/patient/dashboard/PatientVitals.vue";
import PatientDocuments from "@/components/main/patient/dashboard/PatientDocuments.vue";
import RPM from "@/components/main/patient/dashboard/RPM.vue";
import ConnectedDevices from "@/components/main/patient/dashboard/ConnectedDevices.vue";
import BookAppointment from "@/components/main/patient/dashboard/BookAppointment.vue";

const {
  "user/fetchBloodGlucoseRecords": fetchBloodGlucoseRecords,
  "user/fetchPatientProfile": fetchPatientProfile,
  "user/fetchPatientProgressScore": fetchPatientProgressScore,
} = mapActions();

onMounted(() => {
  fetchBloodGlucoseRecords();
  fetchPatientProfile();
  fetchPatientProgressScore();
});
</script>

<style scoped>
.progress-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.progress-circle {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip: rect(0, 100px, 100px, 50px);
}

.progress-circle::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  clip: rect(0, 50px, 100px, 0);
  background: conic-gradient(#3498db 0% 80%, #ecf0f1 80% 100%);
}

.percentage {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #3498db;
}
</style>
