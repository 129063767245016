import axios from "@/api";

export default {
  namespaced: true,

  state() {
    return {
      loading: false,
      prescriptions: [],
      errorMessage: "",
      healthCenters: [],
    };
  },

  getters: {
    getPrescriptions: (state) => state.prescriptions,
    getLoadingState: (state) => state.loading,
    getErrorMessage: (state) => state.errorMessage,
  },

  mutations: {
    SET_PRESCRIPTIONS(state, prescriptions) {
      state.prescriptions = prescriptions;
    },
    SET_LOADING_STATE(state, loadingState) {
      state.loading = loadingState;
    },
    SET_ERROR_MESSAGE(state, errorMessage) {
      state.errorMessage = errorMessage;
    },
    setHealthCenters(state, payload) {
      state.healthCenters = payload;
    },
  },

  actions: {
    async fetchPrescriptions({ commit }) {
      try {
        commit("SET_LOADING_STATE", true);
        commit("SET_PRESCRIPTIONS", []);
        await axios
          .all([
            axios.get("prescriptions/internal"),
            axios.get("prescriptions/external"),
          ])
          .then(
            axios.spread((internalPrescriptions, externalPrescriptions) => {
              commit("SET_PRESCRIPTIONS", [
                ...internalPrescriptions.data.data,
                ...externalPrescriptions.data.data,
              ]);
              commit("SET_LOADING_STATE", false);
            })
          );
      } catch (error) {
        const {
          response: {
            data: { errorMessage },
          },
        } = error;
        commit("SET_ERROR_MESSAGE", errorMessage);
        commit("SET_LOADING_STATE", false);
        commit("SET_PRESCRIPTIONS", []);
      }
    },
    async fetchAllHealthCenters({ commit }) {
      try {
        const response = await axios.get("/data/health_centres");
        commit("setHealthCenters", response.data.posts);
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    async submitTestResult(_, payload) {
      let currentDate = new Date();

      // Get the individual components of the date
      let day = currentDate.getDate().toString().padStart(2, "0");
      let month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      let year = currentDate.getFullYear();
      let hours = currentDate.getHours().toString().padStart(2, "0");
      let minutes = currentDate.getMinutes().toString().padStart(2, "0");
      let seconds = currentDate.getSeconds().toString().padStart(2, "0");

      // Create the formatted date string
      let formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

      try {
        await axios.post("/data/patients/glucose_level", {
          IMEI: payload.imei,
          SN: payload.sn,
          Check_Time: formattedDate,
          Check_Data: {
            Blood_sugar_value: `${payload.bloodSugar} mg/dl`,
            Before_or_after_meals: payload.beforeOrAfterMeals,
          },
        });
      } catch (error) {
        throw error;
      }
    },
    async updateTestNotes(_, payload) {
      try {
        await axios.put(`/data/glucose_level/note/${payload.noteId}`, {
          note: payload.note,
        });
      } catch (error) {
        throw error;
      }
    },
  },
};
