<template>
  <div
    class="absolute left-0 top-full z-10 bg-white w-full h-[140px] py-5 px-6 rounded-lg border border-BluishGrey flex flex-col gap-[10px] overflow-y-scroll no-scrollbar"
  >
    <div
      v-for="(testType, index) in testTypes"
      :key="index"
      @click="testType.isAvailable && selectTestType(testType.name)"
      class="flex items-center gap-4 cursor-pointer"
      :class="!testType.isAvailable && 'opacity-50'"
    >
      <div
        class="w-[30px] h-[30px] bg-LavendaMist rounded-full flex justify-center items-center"
      >
        <img :src="testType.image" :alt="testType.name" />
      </div>

      <p class="text-DarkJungleGreen text-sm">
        {{ testType.name }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestTypesDropdown",
  props: {
    testTypes: {
      type: Array,
      default: [],
    },
  },
  emits: ["setTestType"],
  setup(_, { emit }) {
    const selectTestType = (value) => {
      emit("setTestType", value);
    };

    return {
      selectTestType,
    };
  },
};
</script>
