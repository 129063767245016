<template>
  <div class="space-y-5">
    <div>
      <label for="body" class="text-bold text-DarkJungleGreen">Body</label>
      <textarea
        class="font-extralight w-full border border-BattleshipGrey rounded-lg px-1.5 md:px-3 py-1 md:py-2 placeholder:text-BattleshipGrey text-sm md:text-xl focus:outline-none focus:border-ResolutionBlue focus:ring-0"
        type="text"
        rows="5"
        name=""
        id="body"
        :value="removeHtmlTags(post?.body)"
        @input="updateBody"
      ></textarea>
    </div>
    <div class="flex justify-center items-center space-x-3">
      <button
        type="button"
        class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
        @click="closeEditBlogModal"
      >
        Cancel
      </button>
      <button
        class="bg-ResolutionBlue text-white w-[147px] p-1.5 md:p-3 border rounded-[44px] text-center text-base font-semibold"
        @click="updateBlogBody"
      >
        <span v-if="isLoading">
          <LoadingSpinner />
        </span>
        <span v-else> Update</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onMounted } from "vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { mapActions } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";

const props = defineProps(["post"]);

const closeEditBlogModal = inject("closeEditBlogModal");
const body = ref("");
const isLoading = ref(false);

const updateBody = (event) => {
  body.value = event.target.value;
};

const {
  "blog/updateBlogPostBody": updateBlogPostBody,
  "blog/fetchBlogPosts": fetchBlogPosts,
} = mapActions();

const updateBlogBody = async () => {
  try {
    isLoading.value = true;
    await updateBlogPostBody({
      id: props.post.id,
      body: body.value ? body.value : updateBody.value,
    });
    toast.success("Body updated successfully", {
      autoClose: 1500,
    });
    closeEditBlogModal();
    getAllBlogPosts();
  } catch (error) {
    toast.error("error", {
      autoClose: 1500,
    });
  } finally {
    isLoading.value = false;
  }
};

const getAllBlogPosts = async () => {
  try {
    await fetchBlogPosts();
  } catch (error) {
    console.log("err", error);
  } finally {
  }
};

// remove html tags
const removeHtmlTags = (html) => {
  if (!html) {
    return "";
  }
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

onMounted(() => {
  fetchBlogPosts();
});
</script>

<script>
export default {
  name: "EditBody",
};
</script>
