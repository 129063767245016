<template>
  <ModalWrapper>
    <div
      class="bg-white w-full max-w-[724px] p-6 md:p-12 rounded-[48px] relative"
    >
      <div class="flex justify-between items-center mb-8">
        <h3 class="text-ResolutionBlue text-xl font-bold">Select test type</h3>
        <div class="cursor-pointer" @click="closeSelectTestTypeModal">
          <span
            class="w-4 h-0.5 bg-transparent relative inline-block before:w-full before:h-full before:bg-black before:absolute before:left-0 before:top-0 before:rotate-45 after:w-full after:h-full after:bg-black after:absolute after:left-0 befoafterre:top-0 after:-rotate-45"
          ></span>
        </div>
      </div>

      <div class="grid grid-cols-2 place-items-center gap-6">
        <div
          @click="selectManualType"
          class="bg-BlueChalk hover:bg-[#E9ECF2] w-48 h-48 rounded-xl flex justify-center items-center cursor-pointer transit duration-300"
        >
          <p class="text-DarkJungleGreen text-base font-semibold">Manual</p>
        </div>
        <div
          @click="selectDeviceType"
          class="bg-BlueChalk hover:bg-[#E9ECF2] w-48 h-48 rounded-xl flex justify-center items-center cursor-pointer transit duration-300"
        >
          <p class="text-DarkJungleGreen text-base font-semibold">Device</p>
        </div>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { inject } from "vue";
import ModalWrapper from "./ModalWrapper.vue";

const closeSelectTestTypeModal = inject("closeSelectTestTypeModal");
const openTestStepsModal = inject("openTestStepsModal");
const openSearchPatientModal = inject("openSearchPatientModal");

const selectManualType = () => {
  closeSelectTestTypeModal();
  openTestStepsModal();
};

const selectDeviceType = () => {
  closeSelectTestTypeModal();
  openSearchPatientModal();
};
</script>
