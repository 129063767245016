<template>
  <svg
    width="28"
    height="31"
    viewBox="0 0 28 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Document">
      <path
        id="Document_2"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.88958 0.916748H20.1119C24.6167 0.916748 27.125 3.51258 27.125 7.9605V23.0251C27.125 27.5459 24.6167 30.0834 20.1119 30.0834H7.88958C3.45625 30.0834 0.875 27.5459 0.875 23.0251V7.9605C0.875 3.51258 3.45625 0.916748 7.88958 0.916748ZM8.28333 7.71258V7.698H12.6423C13.2708 7.698 13.7812 8.20842 13.7812 8.83404C13.7812 9.47717 13.2708 9.98758 12.6423 9.98758H8.28333C7.65479 9.98758 7.14583 9.47717 7.14583 8.85008C7.14583 8.223 7.65479 7.71258 8.28333 7.71258ZM8.28333 16.5792H19.7167C20.3438 16.5792 20.8542 16.0688 20.8542 15.4417C20.8542 14.8147 20.3438 14.3028 19.7167 14.3028H8.28333C7.65479 14.3028 7.14583 14.8147 7.14583 15.4417C7.14583 16.0688 7.65479 16.5792 8.28333 16.5792ZM8.28333 23.2438H19.7167C20.2985 23.1855 20.7375 22.6882 20.7375 22.1063C20.7375 21.5084 20.2985 21.0126 19.7167 20.9543H8.28333C7.84583 20.9105 7.42292 21.1147 7.18958 21.4938C6.95625 21.8584 6.95625 22.3397 7.18958 22.7188C7.42292 23.0834 7.84583 23.3022 8.28333 23.2438Z"
        fill="#011D7E"
      />
    </g>
  </svg>
</template>
