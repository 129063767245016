<template>
  <div :class="inputClass">
    <label :for="label" class="px-2 text-[10px] md:text-xs text-OlsoGrey">
      {{ label }}
    </label>
    <input
      :id="label"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :type="type"
      class="border-transparent px-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:outline-none focus:ring-0 disabled:bg-transparent"
      :placeholder="placeholder"
      :disabled="disabled"
      :max="max"
    />
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  label: String,
  modelValue: [String, Number],
  type: {
    type: String,
    default: 'text',
  },
  placeholder: String,
  disabled: {
    type: Boolean,
    default: false,
  },
  max: {
    type: String,
  },
});

const emit = defineEmits(['update:modelValue']);

const inputClass = computed(() => ({
  'flex flex-col w-full pt-4 pb-3 border-b': true,
  'border-BlueChalk': props.modelValue && props.modelValue !== '',
  'border-red-500': !props.modelValue || props.modelValue === '',
}));
</script>
