<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
    @scroll="handleScroll"
  >
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10 overflow-auto no-scrollbar"
    >
      <div class="w-full px-2 md:px-5 py-6 rounded-xl">
        <NotificationTabs
          :filter="filter"
          @setNotificationFilter="setTypeFilter"
        />

        <div
          v-if="isLoading"
          class="w-full h-64 flex justify-center items-center"
        >
          <Spinner />
        </div>

        <div
          v-else-if="allNotifications.length === 0"
          class="w-full h-64 flex justify-center items-center"
        >
          <div
            class="flex flex-col justify-center items-center py-10 space-y-4"
          >
            <div
              class="w-[120px] h-[120px] bg-WhiteLilac p-6 rounded-full flex justify-center items-center"
            >
              <img :src="bellRingingIcon" alt="bell ringing icon" />
            </div>
            <div class="text-Cinder text-center">
              <p class="text-2xl font-bold mb-4">You're All Caught Up!</p>
              <p>You have no new notifications at the moment.</p>
              <p class="text-base">
                Check back later for updates on your appointments, health
                reminders, and personalized health tips.
              </p>
            </div>
          </div>
        </div>

        <div v-else>
          <div v-for="(period, index) in timePeriods" :key="index">
            <h2 class="text-lg font-semibold mb-4 text-center text-SkyGrey">
              {{ period.title }}
            </h2>

            <div v-if="filteredNotificationsByPeriod(period).length > 0">
              <div class="overflow-x-auto overflow-y-visible no-scrollbar">
                <NotificationCard
                  v-for="notification in filteredNotificationsByPeriod(period)"
                  :key="notification.id"
                  :notification="notification"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  mapActions,
  mapGetters,
  mapMutations,
  mapState,
} from "@/hooks/mapStore";
import { toast } from "vue3-toastify";
import Spinner from "@/components/icons/SpinnerIcon.vue";
import NotificationTabs from "@/components/main/patient/notifications/NotificationTabs.vue";
import NotificationCard from "@/components/main/patient/notifications/NotificationCard.vue";
import bellRingingIcon from "@/assets/icons/bell-ringing.svg";

const { notifications } = mapState();
const { "notifications/getAllNotifications": allNotifications } = mapGetters();
const { "notifications/fetchNotifications": fetchNotifications } = mapActions();
const { "notifications/clearNotifications": clearNotifications } =
  mapMutations();

const route = useRoute();
const router = useRouter();

const appointmentTypes = ["all", "alert", "announcement", "reminder"];

let routeQuery = route.query.type;

if (!appointmentTypes.includes(routeQuery)) {
  routeQuery = "all";
}

const filter = ref(routeQuery);

const changeType = (value) => {
  filter.value = value;
  router.push(`/patient/notifications?type=${value}`);
};

const isLoading = computed(() => notifications.value.isLoading);
const timePeriods = ref([
  { key: "today", title: "Today" },
  { key: "yesterday", title: "Yesterday" },
  { key: "last_week", title: "Last Week" },
  { key: "last_month", title: "Last Month" },
]);

let currentPeriodIndex = 0;

const fetchAllNotifications = async () => {
  if (currentPeriodIndex >= timePeriods.value.length) return;
  try {
    const period = timePeriods.value[currentPeriodIndex].key;
    await fetchNotifications({
      type_filter: filter.value,
      time_period: period,
    });
    currentPeriodIndex++;
  } catch (error) {
    toast.error("Error fetching notifications", {
      autoClose: 2000,
    });
  }
};

const filteredNotificationsByPeriod = (period) => {
  return allNotifications.value.filter((notification) => {
    const notificationDate = new Date(notification.created_at);
    const periodStart = new Date();
    const periodEnd = new Date();

    switch (period.key) {
      case "today":
        periodStart.setHours(0, 0, 0, 0);
        periodEnd.setHours(23, 59, 59, 999);
        break;
      case "yesterday":
        periodStart.setDate(periodStart.getDate() - 1);
        periodStart.setHours(0, 0, 0, 0);
        periodEnd.setDate(periodEnd.getDate() - 1);
        periodEnd.setHours(23, 59, 59, 999);
        break;
      case "last_week":
        periodStart.setDate(periodStart.getDate() - 7);
        periodEnd.setHours(23, 59, 59, 999);
        break;
      case "last_month":
        periodStart.setMonth(periodStart.getMonth() - 1);
        periodEnd.setHours(23, 59, 59, 999);
        break;
    }

    return notificationDate >= periodStart && notificationDate <= periodEnd;
  });
};

const setTypeFilter = (type) => {
  console.log({ type });
  changeType(type);
  filter.value = type;
  currentPeriodIndex = 0;
  clearNotifications();
  fetchAllNotifications();
};

watch(allNotifications, (newVal) => {
  console.log("All Notifications:", newVal);
});

watch(filter, () => {
  currentPeriodIndex = 0;
  clearNotifications();
  fetchAllNotifications();
});

const handleScroll = (event) => {
  const bottom =
    event.target.scrollHeight - event.target.scrollTop ===
    event.target.clientHeight;
  if (bottom && !isLoading.value) {
    fetchAllNotifications();
  }
};
</script>
