<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10 overflow-auto no-scrollbar"
    >
      <div class="w-full border px-2 md:px-5 py-6 rounded-xl">
        <div class="flex justify-between items-center gap-3 mb-3">
          <p class="font-urbanist-regular font-bold">My Appointments</p>

          <button
            v-if="getPatientAppointments?.length > 0"
            class="bg-ResolutionBlue py-1.5 px-10 md:py-2 md:px-12 rounded-[40px] text-white text-xs md:text-sm font-semibold hover:bg-DodgerBlue hover:text-white transition-all duration-300"
            @click="openAppointmentModal"
          >
            Book Now
          </button>
        </div>

        <div
          v-if="isLoading"
          class="w-full h-64 flex justify-center items-center"
        >
          <img
            src="../../../assets/icons/loading-01.svg"
            alt="loading icon"
            class="animate-spin"
          />
        </div>

        <div v-else-if="!isLoading && getPatientAppointments?.length > 0">
          <div class="overflow-x-auto custom-scrollbar">
            <table class="w-full">
              <thead>
                <tr>
                  <th
                    class="min-w-[130px] py-3 text-left text-sm font-bold text-[#25282B]"
                  >
                    Health Center
                  </th>

                  <th
                    class="min-w-[80px] py-3 text-left text-sm font-bold text-[#25282B]"
                  >
                    City
                  </th>

                  <th
                    class="min-w-[220px] py-3 pr-2 text-left text-sm font-bold text-[#25282B]"
                  >
                    Address
                  </th>

                  <th
                    class="min-w-[130px] py-3 text-left text-sm font-bold text-[#25282B] relative flex items-center gap-1.5"
                  >
                    <span>HC Phone</span>
                    <span
                      class="bg-ResolutionBlue w-3 h-3 rounded-full text-white text-[0.625rem] flex justify-center items-center cursor-pointer relative group"
                      >i
                      <span
                        class="hidden group-hover:block absolute -bottom-1 right-1/2 translate-x-1/2 translate-y-full bg-GhostWhite w-36 py-2 px-3 rounded-lg text-[#25282B] text-center"
                      >
                        Health center phone</span
                      >
                    </span>
                  </th>

                  <th
                    class="min-w-[100px] py-3 text-left text-sm font-bold text-[#25282B]"
                  >
                    Type
                  </th>

                  <th
                    class="min-w-[100px] py-3 text-left text-sm font-bold text-[#25282B]"
                  >
                    Status
                  </th>

                  <th
                    class="min-w-[100px] py-3 text-left text-sm font-bold text-[#25282B]"
                  >
                    Date
                  </th>

                  <th
                    class="min-w-[100px] py-3 text-left text-sm font-bold text-[#25282B]"
                  >
                    Time
                  </th>

                  <th class="min-w-[100px] py-3"></th>
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="appointment in getPatientAppointments"
                  :key="appointment.id"
                  class="border-t border-gray-200"
                >
                  <td class="min-w-[100px] py-3 text-DavyGrey text-sm">
                    {{ appointment?.health_centre?.name }}
                  </td>

                  <td class="min-w-[80px] py-3 text-DavyGrey text-sm">
                    {{ appointment?.city_of_centre }}
                  </td>

                  <td
                    class="min-w-[220px] py-3 pr-2 text-DavyGrey text-sm cursor-pointer"
                    :title="appointment?.health_centre?.address"
                  >
                    {{ appointment?.health_centre?.address }}
                  </td>

                  <td
                    class="min-w-[130px] py-3 text-DavyGrey text-sm cursor-pointer"
                  >
                    {{ appointment?.health_centre?.phone }}
                  </td>

                  <td
                    class="min-w-[100px] py-3 text-DavyGrey text-sm capitalize"
                  >
                    {{ appointment?.type }}
                  </td>

                  <td
                    class="min-w-[100px] py-3 text-sm first-letter:capitalize"
                    :class="statusStyles(appointment?.status)"
                  >
                    {{ appointment?.status }}
                  </td>

                  <td class="min-w-[200px] py-3 text-DavyGrey text-sm">
                    {{
                      appointment?.date && dateFormatter_2(appointment?.date)
                    }}
                  </td>

                  <td class="min-w-[100px] py-3 text-DavyGrey text-sm">
                    {{ appointment?.time && appointment?.time.slice(0, 5) }}
                  </td>

                  <td
                    v-if="appointment?.status !== 'Cancelled'"
                    class="min-w-[100px] py-3 text-DavyGrey"
                  >
                    <v-menu open-on-hover>
                      <template v-slot:activator="{ props }">
                        <button
                          v-bind="props"
                          class="hover:bg-WhiteLilac p-2 text-2xl md:text-3xl font-bold rounded flex justify-center items-center transition-colors duration-300"
                        >
                          <img :src="optionButton" alt="three dots" />
                        </button>
                      </template>

                      <AppointmentActionBox
                        @edit="setAppointmentModal(appointment)"
                        @cancel="cancelCurrentAppointment(appointment)"
                      />
                    </v-menu>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div v-else class="w-full h-64 flex justify-center items-center">
          <div
            class="flex flex-col justify-center items-center py-16 space-y-4"
          >
            <div
              class="w-fit bg-WhiteLilac p-6 rounded-full flex justify-center items-center"
            >
              <AppointmentIcon />
            </div>
            <p class="text-ResolutionBlue font-semibold">
              You have no upcoming appointments
            </p>
            <p class="text-Charcoal">
              Schedule appointment with doctors at your convenience
            </p>
            <div class="text-center">
              <button
                class="bg-ResolutionBlue py-2 px-12 md:py-[13px] md:px-[52px] rounded-[44px] text-white text-sm font-semibold hover:bg-DodgerBlue hover:text-white transition-all duration-300"
                @click="openAppointmentModal"
              >
                Book Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from "vue";
import { mapActions, mapGetters } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";
import { dateFormatter_2 } from "@/utils/dateFormatter";
import AppointmentIcon from "@/components/icons/AppointmentIcon.vue";
import AppointmentActionBox from "@/components/main/patient/appointment/AppointmentActionBox.vue";
import optionButton from "@/assets/icons/option-button.svg";

const {
  "appointment/fetchPatientAppointments": fetchPatientAppointments,
  "appointment/cancelAppointment": cancelAppointment,
} = mapActions();

const { "appointment/getPatientAppointments": getPatientAppointments } =
  mapGetters();

const openAppointmentModal = inject("openAppointmentModal");
const setAppointmentModalVal = inject("setAppointmentModalVal");
const isLoading = ref(false);

const statusStyles = (status) => {
  if (status === "Scheduled") {
    return "text-BrightBlue";
  } else if (status === "Rescheduled") {
    return "text-BrightBlue";
  } else if (status === "Pending") {
    return "text-OrangePeel";
  } else if (status === "Completed") {
    return "text-DarkMint";
  } else if (status === "Cancelled") {
    return "text-DeepCarminePink";
  } else {
    return "text-DavyGrey";
  }
};

const getAllAppointments = async () => {
  isLoading.value = true;
  try {
    await fetchPatientAppointments();
  } catch (error) {
    console.error("Error fetching patient appointments:", error);
    toast.error("Error fetching appointments", {
      autoClose: 2000,
    });
  } finally {
    isLoading.value = false;
  }
};

getAllAppointments();

onMounted(() => {
  let Script = document.createElement("script");
  Script.setAttribute(
    "src",
    "https://assets.calendly.com/assets/external/widget.js"
  );
  document.head.appendChild(Script);
});

const setAppointmentModal = (value) => {
  setAppointmentModalVal({
    type: "edit",
    country: value.health_centre?.country,
    state: value.state_of_centre,
    city: value.city_of_centre,
    center: value.name_of_centre,
    appointmentReason: value.reason_for_appointment,
    address: value.health_centre.address,
    date: value.date
      ? value.date
      : changeDateFormat(value.health_centre.created_at),
    time: value.health_centre.time
      ? value.health_centre.time
      : value.health_centre.created_at,
    appointmentId: value.id,
  });
  openAppointmentModal();
};

const cancelCurrentAppointment = async (appointment) => {
  try {
    await cancelAppointment({
      id: appointment.id,
      reason: "User requested cancellation",
    });
    toast.success("Appointment canclled successfully", {
      autoClose: 2000,
    });
    getAllAppointments();
  } catch (error) {
    toast.error("Error cancelling appointment", {
      autoClose: 2000,
    });
  }
};
</script>
