<template>
  <div class="flex items-center justify-between gap-3 border-b pb-3">
    <div class="flex items-center cursor-pointer">
      <div class="flex-shrink-0 w-10 h-10">
        <img
          class="w-full h-full rounded-full object-cover"
          :src="
            connections_sent?.patient_recipient?.avatar_url ||
            connections_sent?.health_centre?.avatar_url ||
            noAvatar
          "
          alt=""
        />
      </div>
      <div class="ml-3">
        <p class="font-light whitespace-no-wrap">
          You invited
          <span class="font-bold text-ResolutionBlue">{{
            connections_sent?.patient_recipient?.full_name ||
            connections_sent?.health_centre?.name
          }}</span>
          to be linked as your
          <span class="font-bold">{{ connections_sent?.relationship }}</span>
        </p>
        <div
          class="flex items-center space-x-6 text-SantaGrey font-extralight text-sm"
        >
          <span>
            {{ dateFormatter_2(connections_sent?.created_at) }}
          </span>
          <div class="flex items-center">
            <span>・</span>
            <span> {{ timeFormatter_2(connections_sent?.created_at) }} </span>
          </div>
        </div>
      </div>
    </div>

    <button
      @click="cancelRequest"
      class="border border-ArtyClickRed px-3 py-2 rounded-3xl text-xs font-medium hover:border-ArtyClickRed hover:bg-ArtyClickRed hover:text-white transition-all duration-300 w-16"
    >
      <span v-if="isLoading" class="pl-3">
        <LoadingSpinner />
      </span>
      <span v-else>Cancel</span>
    </button>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { dateFormatter_2 } from "@/utils/dateFormatter";
import { timeFormatter_2 } from "@/utils/dateFormatter";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { mapActions } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";

const noAvatar =
  "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png";

const props = defineProps(["connections_sent"]);

const {
  "accountConnection/cancelSentRequest": cancelSentRequest,
  "accountConnection/fetchPatientRequests": fetchPatientRequests,
} = mapActions();

const isLoading = ref(false);

const cancelRequest = async () => {
  try {
    isLoading.value = true;
    const cancelRequestData = await cancelSentRequest({
      id: props.connections_sent.id,
    });
    if (cancelRequestData) {
      console.log("cancelRequest 11", cancelRequestData);
      toast.success("Request cancel successfully", {
        autoClose: 3000,
      });
      fetchPatientRequests();
      console.log("cancelRequest:", cancelRequestData);
    }
  } catch (error) {
    if (error?.response?.data?.error) {
      toast.error(error.response.data.error, {
        autoClose: 3000,
      });
    } else {
      toast.error("Error cancelling sent request", {
        autoClose: 3000,
      });
    }
  } finally {
    isLoading.value = false;
  }
};
</script>

<script>
export default {
  name: "RequestsSent",
};
</script>
