export const dateFormatter = (dateToFormat) => {
  const originalDate = new Date(dateToFormat);

  const day = originalDate.getDate();
  const month = originalDate.getMonth() + 1; // Months are zero-based, so we add 1
  const year = originalDate.getFullYear();

  // Padding single-digit day and month with leading zeros if necessary
  const formattedDate = `${day < 10 ? "0" : ""}${day}-${
    month < 10 ? "0" : ""
  }${month}-${year}`;

  return formattedDate;
};

export const timeFormatter = (dateTimeString) => {
  const originalDate = new Date(dateTimeString);
  const hours = originalDate.getHours();
  const minutes = originalDate.getMinutes();
  const seconds = originalDate.getSeconds();
  const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

  return formattedTime;
};

export const timeFormatter_2 = (dateTimeString) => {
  const originalDate = new Date(dateTimeString);
  let hours = originalDate.getHours() + 1;
  const minutes = originalDate.getMinutes();
  const ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12 || 12; // Convert hours to 12-hour format
  const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${ampm}`;

  return formattedTime;
};

export const changeDateFormat = (inputDate) => {
  if (inputDate === "") return "";
  // Split the inputDate into day, month, and year components
  let dateComponents = inputDate.split("-");

  // Rearrange the components to the desired format
  let outputDate =
    dateComponents[2] + "-" + dateComponents[1] + "-" + dateComponents[0];

  return outputDate;
};

export const dateFormatter_2 = (date) => {
  const options = {
    weekday: "long",
    day: "2-digit",
    month: "long",
    year: "numeric",
  };
  const formattedDate = new Date(date).toLocaleDateString("en-US", options);
  return formattedDate;
};
