<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div
      class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10 space-y-6"
    >
      <div class="flex items-center space-x-12 mb-6">
        <p class="text-ResolutionBlue font-bold text-xl lg:text-2xl">
          Security and Preferences
        </p>
        <div class="flex items-center space-x-16">
          <div
            class="flex flex-row items-center rounded-sm bg-WhiteLilac p-1.5"
          >
            <span
              @click="toggleTabs('security')"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-10 py-1.5': currentTab !== 'security',
                'font-bold rounded-sm px-10 py-1.5 text-white bg-ResolutionBlue':
                  currentTab === 'security',
              }"
            >
              Security</span
            >
            <span
              @click="toggleTabs('preferences')"
              class="cursor-pointer text-sm"
              :class="{
                'text-PaleSky px-10 py-1.5': currentTab !== 'preferences',
                'font-bold rounded-sm px-10 py-1.5 text-white bg-ResolutionBlue':
                  currentTab === 'preferences',
              }"
            >
              Preferences</span
            >
          </div>
        </div>
      </div>
      <div
        :class="{
          hidden: currentTab !== 'security',
          block: currentTab === 'security',
        }"
        class="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-6"
      >
        <PasswordReset />
        <TwoFactorAuthentication />
      </div>
      <div
        :class="{
          hidden: currentTab !== 'preferences',
          block: currentTab === 'preferences',
        }"
        class="flex gap-6 flex-wrap"
      >
        <div
          class="w-full md:w-[50%] p-10 rounded-3xl border-[0.1px] border-RegentGrey"
        >
          <Display />
        </div>
        <Metrics />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import PasswordReset from "@/components/main/patient/securityAndPreference/security/PasswordReset.vue";
import TwoFactorAuthentication from "@/components/main/patient/twoFactorAuthentication/TwoFactorAuthentication.vue";
import Display from "@/components/main/patient/securityAndPreference/preference/Display.vue";
import Metrics from "@/components/main/patient/securityAndPreference/preference/Metrics.vue";

const router = useRouter();

const tab = router.currentRoute.value.query.tab;
const currentTab = ref(
  tab !== "security" && tab !== "preferences" ? "security" : tab
);

const toggleTabs = (tab) => {
  router.push({
    query: {
      tab,
    },
  });
  currentTab.value = tab;
};
</script>
