<template>
  <div class="space-y-4">
    <div class="flex justify-between items-center flex-wrap">
      <h4 class="font-semibold text-xl opacity-50">Display</h4>
      <p class="text-sm font-bold">Coming soon</p>
    </div>
    <p class="text-GreyChateau text-[10px] md:text-xs opacity-50">
      Choose how your TurboMedics Experience looks for this device.
    </p>
    <div class="space-y-4 md:space-y-8 opacity-50">
      <div class="flex items-center">
        <input
          id="default-radio-1"
          type="radio"
          value=""
          name="default-radio"
          class="w-4 h-4 bg-gray-100 border-red-300 focus:ring-none cursor-pointer"
          disabled
        />
        <label
          for="default-radio-1"
          class="ms-2 text-sm font-medium text-MistBlue"
          >Device Settings</label
        >
      </div>
      <div class="flex items-center">
        <input
          checked
          id="default-radio-2"
          type="radio"
          value=""
          name="default-radio"
          class="w-4 h-4 bg-gray-100 border-red-300 focus:ring-none cursor-pointer"
          disabled
        />
        <label
          for="default-radio-2"
          class="ms-2 text-sm font-medium text-MistBlue"
          >Dark Mode</label
        >
      </div>
      <div class="flex items-center">
        <input
          checked
          id="default-radio-3"
          type="radio"
          value=""
          name="default-radio"
          class="w-4 h-4 bg-gray-100 border-red-300 focus:ring-none cursor-pointer"
          disabled
        />
        <label
          for="default-radio-3"
          class="ms-2 text-sm font-medium text-MistBlue"
          >Light Mode</label
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Display",
};
</script>
