<template>
  <div :class="selectClass">
    <label :for="label" class="px-2 text-[10px] md:text-xs text-OlsoGrey">
      {{ label }}
    </label>
    <select
      :id="label"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
      class="border-transparent pl-1 pr-2 text-xs md:text-sm text-DavyGrey font-bold placeholder:text-DavyGrey focus:outline-none focus:ring-0 cursor-pointer"
    >
      <option value="" disabled>{{ placeholder }}</option>
      <option v-for="(option, index) in options" :key="index" :value="option">
        {{ option }}
      </option>
    </select>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  label: String,
  modelValue: String,
  options: Array,
  placeholder: String,
});

const emit = defineEmits(['update:modelValue']);

const selectClass = computed(() => ({
  'flex flex-col w-full pt-4 pb-3 border-b': true,
  'border-BlueChalk': props.modelValue && props.modelValue !== '',
  'border-red-500': !props.modelValue || props.modelValue === '',
}));
</script>
