<template>
  <div
    class="fixed top-0 left-0 z-[70] bg-[#A3A3A3]/20 backdrop-blur-sm w-full h-screen flex justify-center items-center overflow-y-scroll no-scrollbar"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ModalWrapper",
};
</script>
