<template>
  <div
    class="absolute top-full z-10 -translate-x-full md:translate-x-0 mt-1.5 bg-white w-full min-w-[120px] py-1 border border-BlueChalk rounded-sm text-OlsoGrey text-xs"
  >
    <div class="flex flex-col text-left">
      <p
        v-for="(option, index) in filterOptions"
        :key="index"
        @click="(e) => handleEmitFilter(e, option)"
        class="py-1 px-3 hover:bg-DodgerBlue hover:text-white hover:font-semibold"
      >
        {{ option.name }}
      </p>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["setFilter"]);

function handleEmitFilter(e, value) {
  // e.stopPropagation();
  emit("setFilter", value);
}

const filterOptions = [
  {
    name: "Full name",
    value: "full_name",
  },
  {
    name: "Username",
    value: "username",
  },
  {
    name: "Email",
    value: "email",
  },
  {
    name: "Phone number",
    value: "phone",
  },
];
</script>
