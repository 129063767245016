<template>
  <button
    @click="toggleSidebar"
    class="block lg:hidden transition-transform duration-500"
    :class="isSidebarOpen ? 'translate-x-20' : 'translate-x-0'"
  >
    <span
      class="flex flex-col space-y-1.5 justify-center items-center cursor-pointer w-fit"
    >
      <span
        class="w-7 h-[3px] relative before:absolute before:bg-black before:w-full before:h-full before:left-0 after:absolute after:bg-black after:w-full after:h-full after:left-0 before:transition-all before:duration-500 after:transition-all after:duration-500"
        :class="
          isSidebarOpen
            ? 'bg-transparent before:-rotate-45 after:rotate-45 before:top-0 after:top-0'
            : 'bg-black before:-top-2 after:top-2 before:rotate-0 after:rotate-0'
        "
      ></span>
    </span>
  </button>
</template>

<script setup>
import { inject } from "vue";

const isSidebarOpen = inject("isSidebarOpen");
const toggleSidebar = inject("toggleSidebar");
</script>
