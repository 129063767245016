import axios from "@/api";

const state = {
  patientAppointments: null,
};

const getters = {
  getPatientAppointments: (state) => {
    return state.patientAppointments;
  },
};

const mutations = {
  setPatientAppointments(state, payload) {
    state.patientAppointments = payload?.appointments;
  },
};

const actions = {
  async fetchPatientAppointments({ commit }) {
    try {
      const response = await axios.get(`/data/appointments`);
      commit("setPatientAppointments", response.data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  async bookAppointment(_, payload) {
    try {
      await axios.post(
        `/data/appointment/test_centre/${payload.id}`,
        payload.data
      );
    } catch (error) {
      throw error;
    }
  },

  async editAppointment(_, payload) {
    try {
      await axios.put(
        `data/appointment/${payload.appointmentId}`,
        payload.data
      );
    } catch (error) {
      throw error;
    }
  },

  async cancelAppointment(_, payload) {
    try {
      const response = await axios.put(
        `data/appointment/delete/${payload.id}`,
        {
          reason_for_cancellation: payload.reason,
        }
      );
      console.log("cancel appointment", response.data);
      return response.data.message;
    } catch (error) {
      throw error;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
