<template>
  <div
    class="mx-auto px-2 lg:px-4 py-4 min-h-[calc(100vh-80px)] flex flex-col space-y-8 lg:space-y-0 lg:flex-row lg:space-x-10"
  >
    <div class="xl:mx-10 rounded-lg shadow-xl w-full bg-white p-4 md:p-10">
      <div>
        <div class="border rounded-[20px] pt-11 px-10 pb-7 h-[652px]">
          <div class="flex items-center justify-between gap-2 mb-3">
            <p class="font-bold">Blood Glucose</p>

            <div class="flex justify-between items-center gap-5">
              <div
                class="bg-BlueChalk rounded-[18px] py-1 px-1.5 flex items-center gap-[9px]"
              >
                <button
                  @click="showChart = true"
                  class="py-[5px] px-[9px]"
                  :class="showChart && 'bg-ResolutionBlue rounded-[18px]'"
                >
                  <img
                    :src="showChart ? chartIconLight : chartIcon"
                    alt="chart icon"
                  />
                </button>

                <button
                  @click="showChart = false"
                  class="py-[5px] px-[9px]"
                  :class="!showChart && ' bg-ResolutionBlue rounded-[18px]'"
                >
                  <img
                    :src="!showChart ? listIcon : listIconDark"
                    alt="list icon"
                  />
                </button>
              </div>

              <div class="flex items-center gap-3">
                <button
                  class="p-2 border-[0.3px] border-DavyGrey_2 rounded-md flex items-center gap-2"
                >
                  <span class="text-xs font-semibold">Last week</span>
                  <img
                    src="../../../assets/icons/arrow_drop_down.svg"
                    alt="arrow drop down"
                  />
                </button>

                <button class="bg-ResolutionBlue/[0.05] p-2 rounded-md">
                  <img
                    src="../../../assets/icons/fi_share.svg"
                    alt="share icon"
                  />
                </button>
              </div>
            </div>
          </div>

          <div class="flex justify-between items-center mb-9">
            <span
              class="bg-DarkMint py-2 px-[18px] text-white text-sm font-bold rounded-[18px]"
            >
              Avg: 80mg/dl
            </span>

            <div class="self-end flex items-center gap-5 text-[10px]">
              <div class="flex items-center gap-2">
                <span class="bg-ForestGreen w-1.5 h-1.5 rounded-full"></span>
                <span class="text-ForestGreen">Normal</span>
              </div>
              <div class="flex items-center gap-2">
                <span class="bg-OrangePeel w-1.5 h-1.5 rounded-full"></span>
                <span class="text-OrangePeel">Normal</span>
              </div>
              <div class="flex items-center gap-2">
                <span class="bg-ArtyClickRed w-1.5 h-1.5 rounded-full"></span>
                <span class="text-ArtyClickRed">Normal</span>
              </div>
            </div>
          </div>

          <div v-if="!showChart" class="h-[90%]">
            <div class="overflow-x-auto h-full py-6">
              <table class="w-full">
                <thead>
                  <tr class="text-BalticSea text-sm text-left font-bold">
                    <th class="min-w-[100px] py-3 border-b border-gray-200">
                      Date
                    </th>

                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200"
                    >
                      Time
                    </th>
                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200"
                    >
                      Glucose
                    </th>
                    <th
                      class="min-w-[130px] pl-5 py-3 border-b border-gray-200"
                    >
                      Meal
                    </th>
                  </tr>
                </thead>

                <tbody v-if="!loading && details.length > 0">
                  <tr
                    v-for="(item, index) in details"
                    :key="index"
                    class="border-t border-gray-200 text-DavyGrey text-sm"
                  >
                    <td class="min-w-[100px] py-5">
                      {{ dateFormatter(item.created_at) }}
                    </td>
                    <td class="min-w-[130px] pl-5 py-5">
                      {{ timeFormatter(item.check_time) }}
                    </td>
                    <td class="min-w-[130px] pl-5 py-5 text-OrangePeel">
                      {{ item.glucose_level }} mg/dL
                    </td>
                    <td class="min-w-[130px] pl-5 py-5 text-MistBlue font-bold">
                      {{ item.before_meal ? "Before meal" : "After meal" }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <!-- <p  class="w-full text-center p-4">Loading...</p> -->
              <LoadingSpinner v-if="loading" />
              <p
                v-else-if="!loading && details.length === 0"
                class="w-full text-center p-4"
              >
                No vitals
              </p>
            </div>
          </div>

          <GlucoseLevelChart v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import { mapActions } from "@/hooks/mapStore";
import { dateFormatter } from "@/utils/dateFormatter";
import { timeFormatter } from "@/utils/dateFormatter";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import GlucoseLevelChart from "@/components/main/patient/accountLinking/GlucoseLevelChart.vue";
import chartIcon from "@/assets/icons/fi_bar-chart-2.svg";
import chartIconLight from "@/assets/icons/fi_bar-chart-2-light.svg";
import listIcon from "@/assets/icons/fi_list.svg";
import listIconDark from "@/assets/icons/fi_list-dark.svg";

const route = useRoute();
const id = route.params.id;
const loading = ref(true);
const details = ref([]);
const showChart = ref(false);

const { "accountConnection/getLinkedAccountVitals": getLinkedAccountVitals } =
  mapActions();

const fecthLinkedAccountVitals = async () => {
  try {
    loading.value = true;
    const res = await getLinkedAccountVitals({
      id,
    });
    console.log("linked account vitals:", res);
    details.value = res["Patient results"];
  } catch (error) {
    console.log(error);
  } finally {
    loading.value = false;
  }
};

fecthLinkedAccountVitals();
</script>
