<template>
  <div
    class="fixed top-0 left-0 z-[60] h-screen w-full bg-Dawn/20 backdrop-blur-sm flex justify-center overflow-y-scroll no-scrollbar"
  >
    <div
      class="w-full max-w-[500px] bg-white rounded-[38px] p-6 md:p-10 lg:p-12 m-auto relative"
    >
      <div>
        <div class="flex justify-end items-center mb-6">
          <div class="self-start cursor-pointer" @click="closeDeleteBlogModal">
            <span
              class="w-5 h-0.5 bg-transparent inline-block relative before:absolute before:w-full before:h-full before:bg-GunMetal before:left-0 before:-top-0 before:rounded-sm before:-rotate-45 after:absolute after:w-full after:h-full after:bg-GunMetal after:left-0 after:top-0 after:rounded-sm after:rotate-45"
            ></span>
          </div>
        </div>
        <div class="space-y-4">
          <h2>
            Are you sure you want to delete this post.
            <span class="font-bold"
              >Note that this action can not be undone once deleted</span
            >.
          </h2>
          <div class="flex justify-center space-x-3">
            <button
              type="button"
              class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
              @click="closeDeleteBlogModal"
            >
              Cancel
            </button>
            <button
              class="w-40 border text-center text-[10px] cursor-pointer bg-ArtyClickRed text-white rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
              @click="deleteBlog"
            >
              <span v-if="isLoading">
                <LoadingSpinner />
              </span>
              <span v-else>Delete</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onMounted } from "vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { mapActions } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";

const props = defineProps(["post"]);

const closeDeleteBlogModal = inject("closeDeleteBlogModal");
const isLoading = ref(false);

const {
  "blog/deleteBlogPost": deleteBlogPost,
  "blog/fetchBlogPosts": fetchBlogPosts,
} = mapActions();

const deleteBlog = async () => {
  try {
    isLoading.value = true;
    await deleteBlogPost({
      id: props.post.id,
    });
    toast.success("Post deleted successfully", {
      autoClose: 1500,
    });
    closeDeleteBlogModal();
    getAllBlogPosts();
  } catch (error) {
    toast.error("error", {
      autoClose: 1500,
    });
  } finally {
    isLoading.value = false;
  }
};

const getAllBlogPosts = async () => {
  try {
    await fetchBlogPosts();
  } catch (error) {
    console.log("err", error);
  } finally {
  }
};

onMounted(() => {
  fetchBlogPosts();
});
</script>
