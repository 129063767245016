<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4075_30265)">
      <path
        d="M8.00003 8L6.00003 6L4.00003 8"
        stroke="#FF5200"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.00003 6V10.5"
        stroke="#FF5200"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.195 9.19524C10.6827 8.92937 11.0679 8.50868 11.2899 7.99955C11.512 7.49042 11.5581 6.92185 11.4211 6.38358C11.2841 5.84531 10.9718 5.36799 10.5333 5.02696C10.0949 4.68593 9.55544 4.5006 9.00001 4.50024H8.37001C8.21867 3.91486 7.93659 3.3714 7.54499 2.91073C7.15338 2.45006 6.66243 2.08416 6.10906 1.84054C5.55569 1.59692 4.95429 1.48192 4.35007 1.50418C3.74585 1.52644 3.15454 1.68539 2.6206 1.96907C2.08665 2.25275 1.62397 2.65379 1.26732 3.14203C0.910677 3.63027 0.669356 4.193 0.561501 4.78793C0.453645 5.38286 0.482063 5.9945 0.644617 6.57687C0.807171 7.15923 1.09963 7.69717 1.50001 8.15024"
        stroke="#FF5200"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.00003 8L6.00003 6L4.00003 8"
        stroke="#FF5200"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4075_30265">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
