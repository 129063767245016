<template>
  <div
    class="w-full h-full bg-Alabaster border-[0.1px] border-PaleViolet relative overflow-hidden rounded-3xl"
  >
    <div name="fade" mode="out-in">
      <div
        v-for="(slide, index) in slides"
        :key="slide.id"
        class="absolute top-0 left-0 w-full h-full"
        :class="{
          'opacity-100': activeIndex === index,
          'opacity-0': activeIndex !== index,
        }"
        transition="opacity"
      >
        <div
          class="flex items-center justify-center w-full h-full bg-Alabaster slide_bg rounded-3xl"
        >
          <img
            v-if="slide.image"
            :src="slide.image"
            alt=""
            class="w-full h-full object-cover rounded-3xl"
          />
          <p v-else class="max-w-[16rem] text-base md:max-w-xs md:text-lg">
            {{ slide.content }}
          </p>
        </div>
      </div>
    </div>
    <div class="absolute bottom-4 right-4 flex space-x-2">
      <span
        v-for="(_, index) in slides"
        :key="index"
        class="h-2 md:h-3 w-2 md:w-3 rounded-full border border-PaleViolet bg-ResolutionBlue cursor-pointer"
        :class="{ 'bg-white': activeIndex === index }"
        @click="handleDotClick(index)"
      ></span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: 0,
      slides: [
        {
          id: 1,
          image: require("../../assets/images/nurse-img.png"),
          content: "",
        },
        {
          id: 2,
          image: require("../../assets/images/switch_img2.png"),
          content: "",
        },
        {
          id: 3,
          image: require("../../assets/images/switch_img3.png"),
          content: "",
        },
      ],
    };
  },
  methods: {
    nextSlide() {
      this.activeIndex = (this.activeIndex + 1) % this.slides.length;
    },
    handleDotClick(index) {
      this.activeIndex = index;
    },
  },
  mounted() {
    setInterval(this.nextSlide, 3000);
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 6s;
}
.fade-enter,
.fade-leave-to {
  opacity: 5;
}
/* Add your styles here */
</style>
