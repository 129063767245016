// Helper function to pad single-digit numbers with a leading zero
function padZero(number) {
  return (number < 10 ? "0" : "") + number;
}

export const extractTime = (inputTime) => {
  if (inputTime === "") return "";
  // Parse the input time string
  var date = new Date(inputTime);

  // Set minutes and seconds to zero
  date.setMinutes(0);
  date.setSeconds(0);

  // Extract hours component
  var hours = date.getHours();

  // Pad single-digit values with a leading zero
  hours = hours < 10 ? "0" + hours : hours;

  // Form the output time string in "H:00:00" format
  var outputTime = hours + ":00:00";

  return outputTime;
};
