<template>
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="fi:list">
      <path
        id="Vector"
        d="M4.93774 11.6596H12.6824"
        stroke="white"
        stroke-width="1.78723"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M1.95898 11.6596H1.96565"
        stroke="white"
        stroke-width="1.78723"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M4.93774 8.08511H12.6824"
        stroke="white"
        stroke-width="1.78723"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_4"
        d="M1.95898 8.08511H1.96565"
        stroke="white"
        stroke-width="1.78723"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_5"
        d="M4.93774 4.51065H12.6824"
        stroke="white"
        stroke-width="1.78723"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_6"
        d="M1.95898 4.51065H1.96565"
        stroke="white"
        stroke-width="1.78723"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </svg>
</template>
