import glucoseIcon from "../../assets/icons/testTypes/glucose_icon.svg";
import heartIcon from "../../assets/icons/testTypes/heart.svg";
import lungsIcon from "../../assets/icons/testTypes/lungs.svg";

export const allTestTypes = [
  {
    name: "Glucose",
    image: glucoseIcon,
    isAvailable: true,
  },
  {
    name: "Heart Rate",
    image: heartIcon,
    isAvailable: false,
  },
  {
    name: "Lungs Capacity",
    image: lungsIcon,
    isAvailable: false,
  },
];
