<template>
  <div class="dropdown relative inline-block">
    <img
      :src="profilePicture"
      class="w-6 h-6 md:w-10 md:h-10 rounded-full object-cover cursor-pointer flex items-center"
      alt=""
    />

    <div
      class="dropdown-content hidden absolute right-0 bg-white min-w-[160px] shadow-2xl z-30"
    >
      <router-link
        v-if="!isAdmin"
        to="/patient/settings/account-information"
        class="text-black py-[12px] px-[16px] block hover:text-ResolutionBlue hover:bg-WhiteLilac"
        >Profile</router-link
      >
      <router-link
        v-if="!isAdmin"
        to="/patient/settings"
        class="text-black py-[12px] px-[16px] block hover:text-ResolutionBlue hover:bg-WhiteLilac"
        >Settings</router-link
      >
      <button
        @click="logoutUser"
        class="w-full text-left text-black py-[12px] px-[16px] block hover:text-ResolutionBlue hover:bg-WhiteLilac"
      >
        <span v-if="isLoading">Loging out ...</span>
        <span v-else> Log out</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { mapActions, mapGetters } from "@/hooks/mapStore";

const store = useStore();
const router = useRouter();

const { "auth/logout": logout } = mapActions();

const {
  "auth/getIsAdmin": isAdmin,
  "user/getUserProfilePicture": profilePicture,
} = mapGetters();

const isLoading = ref(false);

// logout user
const logoutUser = async () => {
  try {
    isLoading.value = true;
    const res = await logout();
    if (res) {
      router.push("/auth/sign-in");
      setTimeout(() => {
        store.commit("user/setUser", null, { root: true });
        store.commit(
          "user/setProfilePicture",
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
        );
        store.commit("auth/setLoginStatus", false);
        store.commit("auth/setAdminStatus", false);
      }, 1500);
    }
  } catch (error) {
    throw error;
  } finally {
    isLoading.value = false;
  }
};
</script>

<style scoped>
.dropdown:hover .dropdown-content {
  display: block;
}
</style>
