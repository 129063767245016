<template>
  <ModalWrapper>
    <div
      class="bg-white max-w-[415px] my-8 mx-auto py-8 px-6 rounded-[38px] flex flex-col gap-8"
    >
      <div class="flex justify-between items-center">
        <h2 class="text-Charcoal text-base md:text-xl font-bold">
          Are you sure you want to log out?
        </h2>

        <button @click="$emit('close')" class="size-4 md:size-5">
          <img :src="closeIcon" alt="close icon" class="w-full" />
        </button>
      </div>

      <p class="text-MistBlue text-sm md:text-base">
        You will be signed out of your account.
      </p>

      <div class="flex justify-center gap-2.5">
        <button
          @click="$emit('close')"
          class="bg-white text-ResolutionBlue border border-GreySuit rounded-[44px] text-sm font-semibold py-3 px-[52px]"
        >
          Cancel
        </button>

        <button
          @click="logoutUser"
          class="bg-ResolutionBlue text-white border rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
        >
          <LoadingSpinner v-if="isLoading" />
          <span v-else>Yes log out</span>
        </button>
      </div>
    </div>
  </ModalWrapper>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { mapActions } from "@/hooks/mapStore";
import ModalWrapper from "@/components/main/ui/modals/ModalWrapper.vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import closeIcon from "@/assets/icons/close.svg";

const emit = defineEmits(["close"]);

const router = useRouter();

const store = useStore();

const { "auth/logout": logout } = mapActions();

const isLoading = ref(false);

// logout user
const logoutUser = async () => {
  try {
    isLoading.value = true;
    const res = await logout();
    if (res) {
      emit("close");
      router.push("/auth/sign-in");
      setTimeout(() => {
        store.commit("user/setUser", null, { root: true });
        store.commit(
          "user/setProfilePicture",
          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
        );
        store.commit("auth/setLoginStatus", false);
        store.commit("auth/setAdminStatus", false);
      }, 1500);
    }
  } catch (error) {
    throw error;
  } finally {
    isLoading.value = false;
  }
};
</script>
