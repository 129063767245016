<template>
  <div class="space-y-5">
    <div>
      <label for="body" class="text-bold text-DarkJungleGreen">Body</label>
      <input
        type="file"
        name=""
        id="image"
        @change="updateImage"
        accept="image/*"
        ref="image"
      />
      <!-- 
          :value="post?.image"
  
         -->
      <!-- <input
          class="font-extralight w-full border border-BattleshipGrey rounded-lg px-1.5 md:px-3 py-1 md:py-2 placeholder:text-BattleshipGrey text-sm md:text-xl focus:outline-none focus:border-ResolutionBlue focus:ring-0"
          type="text"
          name=""
          id=""
          :value="post?.image"
          @input="updateImage"
        /> -->
    </div>
    <div class="flex justify-center items-center space-x-3">
      <button
        type="button"
        class="bg-white text-ResolutionBlue border border-ResolutionBlue rounded-[44px] text-sm font-semibold py-[13px] px-[52px]"
        @click="closeEditBlogModal"
      >
        Cancel
      </button>
      <button
        class="bg-ResolutionBlue text-white w-[147px] p-1.5 md:p-3 border rounded-[44px] text-center text-base font-semibold"
        @click="updateBlogImage"
      >
        <span v-if="isLoading">
          <LoadingSpinner />
        </span>
        <span v-else> Update</span>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, onMounted } from "vue";
import LoadingSpinner from "@/components/icons/LoadingSpinner.vue";
import { mapActions } from "@/hooks/mapStore";
import { toast } from "vue3-toastify";

const props = defineProps(["post"]);

const closeEditBlogModal = inject("closeEditBlogModal");
const image = ref("");
const isLoading = ref(false);

const updateImage = (event) => {
  const file = event.target.files[0];
  image.value = file;
};

const {
  "blog/updateBlogPostImage": updateBlogPostImage,
  "blog/fetchBlogPosts": fetchBlogPosts,
} = mapActions();

const updateBlogImage = async () => {
  try {
    isLoading.value = true;
    await updateBlogPostImage({
      id: props.post.id,
      image: image.value,
      // image: image.value ? image.value : updateImage.value,
    });
    toast.success("Image updated successfully", {
      autoClose: 1500,
    });
    closeEditBlogModal();
    getAllBlogPosts();
  } catch (error) {
    toast.error("error", {
      autoClose: 1500,
    });
  } finally {
    isLoading.value = false;
  }
};

const getAllBlogPosts = async () => {
  try {
    await fetchBlogPosts();
  } catch (error) {
    console.log("err", error);
  } finally {
  }
};

onMounted(() => {
  fetchBlogPosts();
});
</script>

<script>
export default {
  name: "EditImage",
};
</script>
